<div class="wrapper">
    <div class="overlay" *ngIf="loading">
        <div class="spinner-wrapper">
            <tds-spinner size="lg" variant="standard"></tds-spinner>
        </div>
    </div>

    <div class="loaded-content" [class.blurred]="loading">
        <ng-content></ng-content>
    </div>
</div>

<!--ADD LANGUAGE-->
<div>
    <div class="tds-row" style="margin-top: 60px; display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <h2>Add Language</h2>
        </div>
        <div class="tds-col-xxlg-4"></div>
    </div>

    <div class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <tds-text-field [(ngModel)]="shortLanguageName" ngDefaultControl type="Text" size="lg" state="default"
                label="Abbreviation" label-position="inside" no-min-width placeholder="Abbreviation">
            </tds-text-field>
        </div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
            <tds-text-field [(ngModel)]="fullLanguageName" ngDefaultControl type="Text" size="lg" state="default"
                label="Full Name" label-position="inside" no-min-width placeholder="Full Name">
            </tds-text-field>
        </div>
    </div>

    <div style="margin-top: 20px; display: flex; justify-content: right">
        <div class="tds-col-xxlg-7 tds-col-xlg-7 tds-col-lg-7 tds-col-md-7 tds-col-sm-12 tds-col-xs-12">
            <tds-button type="button" variant="primary" size="md" text="Create" [disabled]="isCreateDisabled()"
                (click)="createLanguage()">
                <tds-icon slot="icon" size="20px" name="edit"></tds-icon>
            </tds-button>
        </div>
    </div>
</div>

<!--DIVIDER-->
<div style="margin-top: 40px; margin-bottom: 10px; display: flex; justify-content: center;">
    <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
        <tds-divider orientation="horizontal"></tds-divider>
    </div>
</div>

<!-- MANAGE LANGUAGE-->
<div>
    <div class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12 tds-headline-02 wbt-spacing-bottom-8">
            Manage Language
        </div>
        <div class="tds-col-xxlg-4"></div>
    </div>
    <div class="tds-row wbt-spacing-bottom-16" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <tds-dropdown name="dropdown" placeholder="Select Language" size="lg" default-value="2" open-direction="auto">
                <tds-dropdown-option *ngFor="let language of getAdminLanguages()" (click)="getSelectionOnChange(language.id)" value="{{language.id}}">
                    {{language.fullName | titlecase}}
                </tds-dropdown-option>
            </tds-dropdown>
        </div>
        <div class="tds-col-xxlg-4"></div>
    </div>

    <!--MODIFY LANGUAGE ACCORDIAN-->
    <div *ngIf="modifyLanguage" class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
            <tds-accordion>
                <tds-accordion-item [expanded]="panelOpenInfoTexts" (click)="setOpenedLanugagePanel('infotexts', 'open')">
                    <div slot="header" class="tds-headline-04">Infopage texts</div>
                    <div style="margin-bottom: 25px;">
                        <div style="color: darkred">
                            Note: Height of header and body text fields will expand as you type up to the allowed number
                            of character.
                        </div>
                    </div>

                    <div *ngFor="let languageItem of getInfopageItemHeaderList(); let i = index;">
                        <div [style]="(i%2==0 ? 'background-color:rgba(211, 211, 211, 0.459)' : '')">
                            <div class="tds-row" style="display: flex; justify-content: left;">
                                <div  class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                    <h4>Info page text {{i + 1}}</h4>
                                </div>
                            </div>

                            <!--TEXTS-->
                            <div class="tds-row">
                                <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                    <tds-textarea
                                        maxLength="255"
                                        rows="1"
                                        state="default"
                                        label="Header (max 255 chars)"
                                        label-position="inside"
                                        placeholder="Header (max 255 chars)"
                                        id="{{languageItem.id}}"
                                        value="{{getHeader(languageItem.id)}}"
                                        (tdsChange)="setHeader($event)">
                                    </tds-textarea>
                                </div>

                                <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                                    <tds-textarea maxLength="2000"
                                        rows="10"
                                        state="default"
                                        label="Body text (max 2000 chars)"
                                        label-position="inside"
                                        placeholder="Body text (max 2000 chars)"
                                        id="{{getInfopageItemBodyId(languageItem.id)}}"
                                        value="{{getInfopageItemBody(languageItem.key)}}"
                                        (tdsChange)="setBody($event)">
                                    </tds-textarea>
                                </div>
                            </div>

                            <!--BUTTONS-->
                            <div class="tds-row">
                                <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                </div>
                                <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12"
                                    style="display: flex; justify-content: left;">
                                    <tds-button (click)="updateInfopageItem(languageItem.id)" class="wbt-spacing-top-8 wbt-spacing-right-8" id="button{{languageItem.id}}" type="button" variant="primary" size="sm" text="Update">
                                        <tds-icon slot="icon" size="20px" name="save"></tds-icon>
                                    </tds-button>

                                    <tds-button (click)="openDialog(languageItem.id, 'deleteLanguageItem')" class="wbt-spacing-top-8" id="button{{languageItem.id}}" type="button" variant="danger"
                                        size="sm" text="Delete">
                                        <tds-icon slot="icon" size="20px" name="trash"></tds-icon>
                                    </tds-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="wbt-spacing-bottom-8 wbt-spacing-top-8">&nbsp; </div>

                    <!--NEW INFO PAGE HEADER + BODY TEXT-->
                    <div class="wbt-spacing-bottom-8 wbt-spacing-top-8" style=" border-top: 1px solid darkgrey;">
                        <div class="tds-row" style="display: flex; justify-content: left;">
                            <div
                                class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                <h4 style="text-align: left;">New info page text</h4>
                            </div>
                        </div>

                        <div class="tds-row">
                            <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                <tds-textarea
                                    maxLength="255"
                                    ngDefaultControl
                                    rows="1"
                                    state="default"
                                    label="Header (max 255 chars)"
                                    label-position="inside"
                                    placeholder="Header (max 255 chars)"
                                    [(ngModel)]="newHeader">
                                </tds-textarea>
                            </div>

                            <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                                <tds-textarea maxLength="2000"
                                    ngDefaultControl
                                    rows="10"
                                    state="default"
                                    label="Body text (max 2000 chars)"
                                    label-position="inside"
                                    placeholder="Body text (max 2000 chars)"
                                    [(ngModel)]="newBody">
                                </tds-textarea>
                            </div>
                        </div>

                        <div class="tds-row" style="margin-top: 10px; display: flex; justify-content: left;">
                            <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                            </div>
                            <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                            <tds-button (click)="createInfopageItem()" class="wbt-spacing-top-8" type="button" variant="primary"
                                    size="sm" text="Create">
                                    <tds-icon slot="icon" size="20px" name="save"></tds-icon>
                                </tds-button>
                            </div>
                        </div>
                    </div>
                </tds-accordion-item>

                <tds-accordion-item [expanded]="panelOpenAppTexts"
                    (click)="setOpenedLanugagePanel('apptexts', 'open')">
                    <div slot="header" class="tds-headline-04">Application texts</div>
                    <div style="margin-bottom: 25px;">
                        <div>
                            Edit translation text for selected language ({{getSelLanguagName()}}).
                            <i style="color:darkred">Note: update happens automatically when pressing enter or leaving
                                edited field</i>
                        </div>
                    </div>
                    <div *ngFor="let languageItem of getEnglishItemList()">
                        <div class="tds-row" style="display: flex; justify-content: center;">

                            <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                                <tds-textarea style="width:100%"
                                    label="English"
                                    label-position="inside"
                                    ngDefaultControl
                                    [(ngModel)]="languageItem.textString"
                                    value="{{languageItem.textString}}"
                                    disabled>
                                </tds-textarea>
                            </div>
                            <div class="wbt-spacing-bottom-8     tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                                <tds-textarea style="width:100%"
                                    label="Translation"
                                    label-position="inside"
                                    maxlength="2000"
                                    [(ngModel)]="languageItem.textString"
                                    (tdsChange)="setLanguageItem($event)"
                                    [value]="getTranslationItem(languageItem.key)"
                                    id="{{languageItem.key}}">
                                </tds-textarea>
                            </div>
                        </div>
                    </div>
                </tds-accordion-item>

                <tds-accordion-item [expanded]="panelSessionQuestionsTexts"
                    (click)="setOpenedLanugagePanel('sessionquestionstexts', 'open')">
                    <div slot="header" class="tds-headline-04">Session Questions Texts</div>
                    <div style="margin-bottom: 25px;">
                        <div style="color: darkred">
                            Note: Height of header and body text fields will expand as you type up to the allowed number
                            of character.
                        </div>
                    </div>

                    <div *ngFor="let languageItem of getSessionpageQuestionsHeaderList(); let i = index;">
                        <div [style]="(i%2==0 ? 'background-color:rgba(211, 211, 211, 0.459)' : '')">
                            <div class="tds-row" style="display: flex; justify-content: left;">
                                <div  class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                    <h4>Session question {{i + 1}}</h4>
                                </div>
                            </div>

                            <!--TEXTS-->
                            <div class="tds-row">
                                <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                    <tds-textarea
                                        rows="1"
                                        state="default"
                                        label="Header (max 255 chars)"
                                        label-position="inside"
                                        maxlength="255"
                                        placeholder="Header (max 255 chars)"
                                        id="{{languageItem.id}}"
                                        value="{{getHeader(languageItem.id)}}"
                                        (tdsChange)="setHeader($event)">
                                    </tds-textarea>
                                </div>

                                <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                                    <tds-textarea maxLength="2000"
                                        rows="10"
                                        state="default"
                                        label="Body text (max 2000 chars)"
                                        label-position="inside"
                                        placeholder="Body text (max 2000 chars)"
                                        id="{{getSessionpageQuestionsItemBodyId(languageItem.id)}}"
                                        value="{{getSessionpageQuestionsItemBody(languageItem.key)}}"
                                        (tdsChange)="setBody($event)">
                                    </tds-textarea>
                                </div>
                            </div>

                            <!--BUTTONS-->
                            <div class="tds-row">
                                <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                </div>
                                <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12"
                                    style="display: flex; justify-content: left;">
                                    <tds-button (click)="updateSessionpageQuestionsItem(languageItem.id)" class="wbt-spacing-top-8 wbt-spacing-right-8" id="button{{languageItem.id}}" type="button" variant="primary" size="sm" text="Update">
                                        <tds-icon slot="icon" size="20px" name="save"></tds-icon>
                                    </tds-button>

                                    <tds-button (click)="openDialog(languageItem.id, 'deleteLanguageItem')" class="wbt-spacing-top-8" id="button{{languageItem.id}}" type="button" variant="danger"
                                        size="sm" text="Delete">
                                        <tds-icon slot="icon" size="20px" name="trash"></tds-icon>
                                    </tds-button>
                                </div>
                            </div>
                        </div>
                    </div>

                </tds-accordion-item>

            </tds-accordion>
        </div>
    </div>
</div>

<!--LANGUAGE TEXTS-->
<div *ngIf="true">
    <!--DIVIDER-->
    <div class="tds-row" style="margin-top: 40px;margin-bottom: 20px;">

        <div class="tds-col-sm-12 tds-col-md-3"></div>
        <div class="tds-col-md-6  tds-col-sm-12" style="border-bottom: 1px solid darkgrey;">

        </div>
        <div class="tds-col-sm-12 tds-col-md-3"></div>
    </div>
    <!-- modifyLanguage"> -->
    <!--SELECT LANGUAGE TO MANAGE-->
    <div class="tds-row wbt-spacing-bottom-16">
        <div class="tds-col-sm-12 tds-col-md-3"></div>
        <div class="tds-col-md-6  tds-col-sm-12" >
            <div class="tds-row">
                <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                    <tds-text-field
                        label="Full Name"
                        label-position="inside"
                        ngDefaultControl
                        maxlength="128"
                        placeholder="{{getLanguageFullName() | titlecase}}"
                        (tdsChange)="langTextChanged = true"
                        [(ngModel)]="updateFullLanguageName">
                    </tds-text-field>
                </div>
                <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                    <tds-text-field
                        label="Abbreviation"
                        label-position="inside"
                        ngDefaultControl
                        maxlength="2"
                        placeholder="{{getLanguageShortName() | titlecase}}"
                        (tdsChange)="langTextChanged = true"
                        [(ngModel)]="updateShortLanguageName">
                    </tds-text-field>
                </div>
            </div>
        </div>
        <div class="tds-col-md-6  tds-col-sm-12"></div>
    </div>

    <div class="tds-row wbt-spacing-bottom-16">
        <div class="tds-col-sm-12 tds-col-md-3"></div>
        <div class="tds-col-md-6  tds-col-sm-12" >
            <div class="tds-row">
                <div class=" wbt-spacing-bottom-16 wbt-spacing-left-16">
                    <tds-toggle style="float:left" [checked]="languagePublished" (tdsToggle)="languagePublished = !languagePublished; langTextChanged = true">
                        <div slot="label">
                            Published
                        </div>
                    </tds-toggle>
                </div>
                <div  class="tds-col-xs-12 wbt-spacing-bottom-8 wbt-spacing-left-8">
                    <tds-button class="wbt-spacing-right-8"
                        style="float:left" type="button" variant="primary" size="md" text="Update"
                        [disabled]="!langTextChanged"
                        (click)="updateLanguage()">
                        <tds-icon slot="icon" size="20px" name="edit"></tds-icon>
                    </tds-button>
                    <tds-button style="float:left" type="button" variant="danger" size="md" text="Delete"
                        (click)="openDialog(selected, 'deleteLanguage')"
                        [disabled]="selected == englishLanguage">
                        <tds-icon slot="icon" size="20px" name="trash"></tds-icon>
                    </tds-button>
                </div>
            </div>
        </div>
        <div class="tds-col-md-6  tds-col-sm-12"></div>
    </div>
</div>


<div *ngIf="showConfirm">
    <tds-modal
        header="Confirm delete"
        show
        size="lg"
        actions-position="static" >
        <span slot="body">
            <p>Are you sure you want to delete?</p>
        </span>
        <span slot="actions">
            <tds-button data-dismiss-modal size="md" (click)="confirmDelete()" text="Delete" variant="danger" class="wbt-spacing-right-8"></tds-button>
            <tds-button data-dismiss-modal size="md" text="Cancel" variant="secondary" (click)="showConfirm = false;"></tds-button>
        </span>
    </tds-modal>
</div>

<div class="wbt-spacing-bottom-16 wbt-spacing-right-16"
    *ngIf="errMessage != ''"
    style="position: fixed; bottom: 0px; right: 0;">
    <tds-toast
        variant="{{toastVariant}}"
        header="{{toastHeader}}"
        subheader="{{errMessage}}" >
    </tds-toast>
</div>

