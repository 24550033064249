import { Component, OnInit } from '@angular/core';
import { InternalMsgService } from '../service/internal.msg.service';
import { LanguageListService } from '../service/languagelist.service';
import { Subscription } from 'rxjs';
import { LanguageItem } from '../model/languageitem';
import { errorCopy } from '../common/errorCopy';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    static snackBarErrMsg: string = '';
    languageSubscr: Subscription = new Subscription();
    languageItemList: LanguageItem[] = [];

    errMessage: string = '';
    toastVariant: string = '';
    toastHeader: string = '';
    toastSubHeader: string = ''

    panelOpenState = false;
    constructor(private msgService: InternalMsgService,
        private languageListService: LanguageListService,
        private errCopy: errorCopy) {
    }

    ngOnInit(): void {
        this.msgService.onSelectedMenuSet(0);
        this.initServices();
    }

    initServices() {
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(words => {
            if (this.languageListService.listErrorMsg != '') {
                this.openSnackBarErr(this.languageListService.listErrorMsg);
                this.languageListService.listErrorMsg = '';
            }
            this.languageItemList = words;
        });
        this.languageListService.fetchData();
    }

    getHeaderItems(): LanguageItem[] {
        if (this.languageItemList.length != 0) {
            return this.languageItemList.filter(x => x.group == "home" && x.key.startsWith("header"));
        }
        return []
    }

    getBodyItem(key: string) {
        var body = "body" + key.slice(6);
        return this.languageListService.getLanguageItem(body).textString;
    }

    getIsLoading() {
        return this.languageListService.loading;
    }

    openSnackBarInfo(message: string): void {
        this.toastVariant = 'information';
        this.toastHeader = 'Information';
        this.errMessage = message;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 7000 
        );  
    }

    openSnackBarErr(message: string): void {
        this.toastVariant = 'error';
        this.toastHeader = 'Error';
        this.errMessage = 'An unexpected error occurred. The error was: ' + message;;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 20000 
        );  
    }

    getLanguageItem(key: string) {
        return this.languageListService.getLanguageItem(key).textString;
    }
  
}
