import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReturnObject } from '../model/returnobject';

import { SessionUser } from '../model/sessionuser';
import { InternalMsgService } from '../service/internal.msg.service';
import { LanguageListService } from '../service/languagelist.service';
import { CompareService } from '../service/compare.service';
import { MeetingItem } from '../model/meetingitem';
import { SessionItem } from '../model/sessionitem';
import { LanguageItem } from '../model/languageitem';
import { Faces } from '../interface/face-interface';
import { errorCopy } from '../common/errorCopy';

const XLIMIT = 280;
const YLIMIT = 330;


@Component({
    selector: 'app-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {
    static snackBarErrMsg: string = '';
    languageSubscr: Subscription = new Subscription();
    languageItemList: LanguageItem[] = [];
    participantsLeft: SessionUser[] = [];
    participantsRight: SessionUser[] = [];
    meetingList: MeetingItem[] = [];
    dateListLeft: SessionItem[] = [];
    dateListRight: SessionItem[] = [];
    loading: boolean = false;
    selectedLeft: string = "";
    selectedRight: string = "";
    selectedDateLeft: number = -1;
    selectedDateRight: number = -1;
    circleTitle: string = "";
    iterationList: number[] = [6, 12, 17, 22, 26, 33, 38, 43, 47, 50];
    sections: string[] = ["manageability", "comprehensibility", "meaningfulness", "recovery"];

    errMessage: string = '';
    toastVariant: string = '';
    toastHeader: string = '';
    toastSubHeader: string = ''


    constructor(private languageListService: LanguageListService,
        private compareService: CompareService,
        private msgService: InternalMsgService,
        private errCopy: errorCopy) { }

    ngOnInit(): void {
        this.initServices();
    }

    initServices() {
        this.msgService.onSelectedMenuSet(2);
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(
            words => {
                this.languageItemList = words;
            });
        this.languageListService.fetchData();
        this.getMeetingList();
    }

    faces: Faces[] = [
        {
            value: 'excellent',
            label: 'excellentface',
            img: "../../assets/excellent.png",
            myImg: "../../assets/myexcellent.png",
            modImg: "../../assets/modexcellent.png"
        },
        {
            value: 'good',
            label: 'goodface',
            img: "../../assets/good.png",
            myImg: "../../assets/mygood.png",
            modImg: "../../assets/modgood.png"
        },
        {
            value: 'insufficient',
            label: 'insufficientface',
            img: "../../assets/insufficient.png",
            myImg: "../../assets/myinsufficient.png",
            modImg: "../../assets/modinsufficient.png"
        },
        {
            value: 'poor',
            label: 'poorface',
            img: "../../assets/poor.png",
            myImg: "../../assets/mypoor.png",
            modImg: "../../assets/modpoor.png"
        }
    ];

    getMeetingList() {
        this.loading = true;
        this.compareService.getMeetingList()
            .subscribe({
                next: (result: ReturnObject<MeetingItem[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.meetingList = result.value;
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    getSessions(meetingId: number, isLeft: boolean = false) {
        this.loading = true;
        this.selectedLeft = meetingId.toString();
        this.compareService.getSessions(meetingId)
            .subscribe({
                next: (result: ReturnObject<SessionItem[]>) => {
                    this.participantsRight = [];
                    this.participantsLeft = [];
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    if (isLeft) {
                        this.dateListLeft = result.value;
                        this.selectedRight = this.selectedLeft;
                        this.dateListRight = result.value;
                    }
                    else {
                        this.dateListRight = result.value;
                    }
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    getParticipants(sessionId: number, isLeft: boolean = false) {
        this.loading = true;
        this.compareService.getParticipants(sessionId)
            .subscribe({
                next: (result: ReturnObject<SessionUser[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    if (isLeft) {
                        this.participantsLeft = [];
                        result.value.forEach(element => {
                            this.participantsLeft.push(new SessionUser(element));
                        });
                        this.setXY(this.participantsLeft);
                    }
                    else {
                        this.participantsRight = [];
                        result.value.forEach(element => {
                            this.participantsRight.push(new SessionUser(element));
                        });
                        this.setXY(this.participantsRight);
                    }
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }


    // -----------------------------------------------------------------------------------
    // Misc
    // -----------------------------------------------------------------------------------
    getMeetingName() {
        const meetingName = this.meetingList.find(x => x.id == this.selectedLeft)?.name;

        return this.meetingList && this.selectedLeft != ""
            ? meetingName
            : '---';
    }

    openSnackBarInfo(message: string): void {
        this.toastVariant = 'information';
        this.toastHeader = 'Information';
        this.errMessage = message;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 7000 
        );  
    }

    openSnackBarErr(message: string): void {
        this.toastVariant = 'error';
        this.toastHeader = 'Error';
        this.errMessage = 'An unexpected error occurred. The error was: ' + message;;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 20000 
        );  
    }



    getNumberOfParticipants(participants: SessionUser[]) {
        return participants.length;
    }

    getLanguageItem(key: string) {
        return this.languageListService.getLanguageItem(key).textString;
    }

    getLabelLength(label: string) {
        return label.length;
    }

    getFirstHalfOfLabel(label: string) {
        var temp = label.split(' ').slice(0, label.split(" ").length / 2).join(' ');
        return temp;
    }

    getRestOfLabel(label: string) {
        return label.split(' ').slice(label.split(" ").length / 2).join(' ');
    }

    getFaceColor(item: string): string {
        switch (item) {
            case "excellent":
                return "#excellent";
            case "good":
                return "#good";
            case "insufficient":
                return "#unsatisfying";
            case "poor":
                return "#bad";
            default:
                return "";
        }
    }

    getTitle() {
        return this.circleTitle;
    }

    setTitle(event: any) {
        if (event.offsetX < XLIMIT && event.offsetY < YLIMIT) {
            this.circleTitle = this.getLanguageItem("manageability") +
                "\n\n" + this.getLanguageItem("manageabilityrow1") +
                "\n" + this.getLanguageItem("manageabilityrow2") +
                "\n" + this.getLanguageItem("manageabilityrow3") +
                "\n" + this.getLanguageItem("manageabilityrow4");
        }
        else if (event.offsetX < XLIMIT && event.offsetY > YLIMIT) {
            this.circleTitle = this.getLanguageItem("meaningfulness") +
                "\n\n" + this.getLanguageItem("meaningfulnessrow1") +
                "\n" + this.getLanguageItem("meaningfulnessrow2") +
                "\n" + this.getLanguageItem("meaningfulnessrow3") +
                "\n" + this.getLanguageItem("meaningfulnessrow4");
        }
        else if (event.offsetX > XLIMIT && event.offsetY < YLIMIT) {
            this.circleTitle = this.getLanguageItem("comprehensibility") +
                "\n\n" + this.getLanguageItem("comprehensibilityrow1") +
                "\n" + this.getLanguageItem("comprehensibilityrow2") +
                "\n" + this.getLanguageItem("comprehensibilityrow3") +
                "\n" + this.getLanguageItem("comprehensibilityrow4");
        }
        else if (event.offsetX > XLIMIT && event.offsetY > YLIMIT) {
            this.circleTitle = this.getLanguageItem("recovery") +
                "\n\n" + this.getLanguageItem("recoveryrow1") +
                "\n" + this.getLanguageItem("recoveryrow2") +
                "\n" + this.getLanguageItem("recoveryrow3") +
                "\n" + this.getLanguageItem("recoveryrow4");
        }
    }

    clearTitle() {
        this.circleTitle = "";
    }

    setXY(list: SessionUser[]) {
        var startX = 820;
        var startY = 915;
        var downX = startX - 90;
        var downY = startY - 75;
        var upX = startX + 90;
        var upY = startY + 75;
        var iteration = 0;
        list.forEach(x => {
            if (this.iterationList.includes(iteration)) {
                downY -= 105;
                upY += 105;
                if (iteration < 27) {
                    downX = startX - 90;
                    upX = startX + 90;
                }
                else {
                    downX = startX - 130;
                    upX = startX + 130;
                }
            }
            if (iteration == 28) {
                downX = startX - 130;
                upX = startX + 130;
                downY = startY - 115;
                upY = startY + 115;
            }
            x.handlingX = downX;
            x.handlingY = downY;
            x.understandableX = upX;
            x.understandableY = downY;
            x.meaningfulnessX = downX;
            x.meaningfulnessY = upY;
            x.recoverX = upX;
            x.recoverY = upY;
            downX -= 105;
            upX += 105;
            iteration += 1;
        });
    }
}
