<div class="tds-row" style="margin-bottom: 40px;">
    <div class="tds-col-xxlg-3 tds-col-xlg-3 tds-col-lg-3 tds-col-md-0 tds-col-sm-0 tds-col-xs-0"></div>

    <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="text-align: center;">
            <h4>{{getLanguageItem( 'headercreatemeeting' )}}</h4>
        </div>
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="margin-top: 20px; display: flex; justify-content: center;">
            <tds-text-field style="width: 400px;" [(ngModel)]="meetingName" ngDefaultControl type="text" size="lg"
                state="default" label="{{getLanguageItem( 'titlemeetingname' )}}" label-position="inside" no-min-width
                placeholder="{{getLanguageItem( 'titlemeetingname' )}}">
            </tds-text-field>
        </div>
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="margin-top: 20px; display: flex; justify-content: center;">
            <tds-button type="button" variant="primary" size="md" text="{{getLanguageItem( 'titlecreate' )}}"
                [disabled]="isCreateDisabled()" (click)="createMeeting()">
                <tds-icon slot="icon" size="20px" name="edit"></tds-icon>
            </tds-button>
        </div>
        <div *ngIf="myMeetings.length > 0">
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="margin-top: 20px">
                <tds-divider orientation="horizontal"></tds-divider>
            </div>
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="text-align: center;">
                <h2>{{getLanguageItem( 'headermymeetings' )}}</h2>
            </div>
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="text-align: center;">
                <h4>{{getLanguageItem( 'headercopyparticipantlink' )}}</h4>
            </div>
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="text-align: center;">
                <h4>{{getLanguageItem( 'headerstartmeetingbuttonorcopymoderatorlink' )}}</h4>
            </div>

            <div id="content-desktop" class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                <tds-table no-min-width responsive="true">
                    <tds-table-body>
                        <tds-table-body-row *ngFor="let item of dataSource">
                            <tds-body-cell style="vertical-align: middle;">
                                {{item.name}}
                            </tds-body-cell>
                            <tds-body-cell>
                                <tds-button type="button" variant="secondary" size="sm" text="{{getLanguageItem( 'titlestartmeetingbutton' )}}" (click)="startMeeting(item.key, item.moderatorKey)">
                                    <tds-icon slot="icon" size="16px" name="send"></tds-icon>
                                </tds-button>
                            </tds-body-cell>
                            <tds-body-cell id="pitem{{item.id}}" style="vertical-align: middle;">
                                <a class="wbt-spacing-right-8" style="cursor: pointer;" cdkCopyToClipboard="{{ createMeetingLink(item.key) }}">
                                    {{getLanguageItem( 'titleparticipantlink' )}}
                                </a>
                                <tds-icon style="cursor: pointer;" name="copy" size="20px" cdkCopyToClipboard="{{ createMeetingLink(item.key) }}"></tds-icon>
                                <tds-tooltip selector="#pitem{{item.id}}" text="{{getLanguageItem( 'titlecopy' )}}"></tds-tooltip>
                            </tds-body-cell>
                            <tds-body-cell id="mitem{{item.id}}" style="vertical-align: middle;">
                                <a class="wbt-spacing-right-8" style="cursor: pointer;" cdkCopyToClipboard="{{ createModeratorLink(item.key, item.moderatorKey) }}">
                                    {{getLanguageItem( 'titlemoderatorlink' )}}
                                </a>
                                <tds-icon style="cursor: pointer;" name="copy" size="20px" cdkCopyToClipboard="{{ createModeratorLink(item.key, item.moderatorKey) }}"></tds-icon>
                                <tds-tooltip selector="#mitem{{item.id}}" text="{{getLanguageItem( 'titlecopy' )}}"></tds-tooltip>
                            </tds-body-cell>
                        </tds-table-body-row>
                    </tds-table-body>
                </tds-table>
            </div>

            <div id="content-mobile" class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                <tds-table no-min-width responsive="true">
                    <tds-table-header>
                        <tds-header-cell>Meeting name</tds-header-cell>
                        <tds-header-cell>Start meeting</tds-header-cell>
                        <tds-header-cell>Meeting link</tds-header-cell>
                        <tds-header-cell>Moderator link</tds-header-cell>
                    </tds-table-header>
                    <tds-table-body>
                        <tds-table-body-row *ngFor="let item of dataSource">
                            <tds-body-cell style="vertical-align: middle;" >
                                {{item.name}}
                            </tds-body-cell>
                            <tds-body-cell>
                                <tds-button type="button" variant="secondary" size="sm" (click)="startMeeting(item.key, item.moderatorKey)">
                                    <tds-icon slot="icon" size="16px" name="send"></tds-icon>
                                </tds-button>
                            </tds-body-cell>
                            <tds-body-cell style="vertical-align: middle;" cdkCopyToClipboard="{{ createMeetingLink(item.key) }}">
                                <tds-icon name="copy" size="24px" cdkCopyToClipboard="{{ createMeetingLink(item.key) }}"></tds-icon>
                            </tds-body-cell>
                            <tds-body-cell style="vertical-align: middle;" cdkCopyToClipboard="{{ createModeratorLink(item.key, item.moderatorKey) }}">
                                <tds-icon name="copy" size="24px" cdkCopyToClipboard="{{ createModeratorLink(item.key, item.moderatorKey) }}"></tds-icon>
                            </tds-body-cell>
                        </tds-table-body-row>
                    </tds-table-body>
                </tds-table>
            </div>
        </div>
    </div>
    <div class="tds-col-xxlg-3 tds-col-xlg-3 tds-col-lg-3 tds-col-md-0 tds-col-sm-0 tds-col-xs-0"></div>
</div>

<div class="wbt-spacing-bottom-16 wbt-spacing-right-16"
    *ngIf="errMessage != ''"
    style="position: fixed; bottom: 0px; right: 0;">
    <tds-toast
        variant="{{toastVariant}}"
        header="{{toastHeader}}"
        subheader="{{errMessage}}" >
    </tds-toast>
</div>
