import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Language } from '../model/language';
import { LanguageItem } from '../model/languageitem';

@Injectable( {
    providedIn: 'root'
} )
export class AdminService {

    constructor( private api: APIService) {
    }

    postItemToDb(item: LanguageItem) {
        return this.api.postAPI( `admin/languageitem`, item);
    }

    postLanguageToDb(language: Language) {
        return this.api.postAPI( `admin/language`, language);
    }

    deleteLanguageFromDb(languageId: number): any {
        return this.api.deleteByEndpointApi( `admin/deletelanguage/${languageId}`);
    }

    deleteLanguageItemFromDb(languageItemId: string): any {
        return this.api.deleteByEndpointApi( `admin/deletelanguageitem/${languageItemId}` );
    }

    getAdminPrivileges(){
        return this.api.getAPI( `admin/getadminusers` );
    }

}
