import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReturnObject } from './model/returnobject';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
    MsalService,
    MsalBroadcastService,
    MSAL_GUARD_CONFIG,
    MsalGuardConfiguration
} from '@azure/msal-angular';

import { InteractionStatus } from '@azure/msal-browser';

import { InternalMsgService } from './service/internal.msg.service';
import { SettingsService } from './service/settings.service';
import { LanguageListService } from './service/languagelist.service';
import { LanguageItem } from './model/languageitem';
import { LanguageAdmin } from './model/languageadmin';
import { AdminService } from './service/admin.service';

import { Language } from './model/language';
import { UserService } from './service/user.service';
import { LanguageFlags } from './interface/language-flags-interface';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    loadCorporateUI = false;
    activeMenu: string[] = ['', 'bg-light', '', '', ''];
    menuIndexSubscr: Subscription = new Subscription();
    languageSubscr: Subscription = new Subscription();;
    loadingText: string = 'Loading...';
    languageItemList: LanguageItem[] = [];
    languageList: Language[] = [];
    title = 'wbtui';
    mobileNavOpen = false;

    errMessage: string = '';
    toastVariant: string = '';
    toastHeader: string = '';
    toastSubHeader: string = ''

    selectedMenu: Boolean[] = [false, false, false, false, false];

    languageFlags: LanguageFlags[] = [
        { shortName: 'NL', flag: './flags/nl.png' },
        { shortName: 'EN', flag: './flags/en.png' },
        { shortName: 'FI', flag: './flags/fi.png' },
        { shortName: 'FR', flag: './flags/fr.png' },
        { shortName: 'PL', flag: './flags/pl.png' },
        { shortName: 'PT', flag: './flags/pt.png' },
        { shortName: 'ES', flag: './flags/es.png' },
        { shortName: 'SE', flag: './flags/se.png' },
    ];

    // ******************************************************************
    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    // ******************************************************************

    constructor(
        // ******************************************************************
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        // ******************************************************************
        private router: Router,
        private route: ActivatedRoute,
        private settingsService: SettingsService,
        private msgService: InternalMsgService,
        public languageListService: LanguageListService,
        private adminService: AdminService,
        private userService: UserService,) {
        this.loadCorporateUI = true;
        setTimeout(() => { this.loadCorporateUI = true }, 100);
        localStorage.setItem("menues", JSON.stringify(this.activeMenu));
    }

    changeActiveMenu(activeMenu: number) {
        // Set timeout for updating the menu selection when using browser
        // back/forth buttons to prevent:
        // ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
        setTimeout(() => {
            this.activeMenu = ['', '', '', '', ''];
            this.activeMenu[activeMenu] = 'bg-light';
            localStorage.setItem("menues", JSON.stringify(this.activeMenu));
        }
            , 0);
    }

    @HostListener('window:beforeunload')
    ngOnDestroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();

        this.menuIndexSubscr.unsubscribe();
        this.languageSubscr.unsubscribe();
        //this.settingsService.logout();
    }

    ngOnInit() {
        // ******************************************************************
        this.isIframe = window !== window.parent && !window.opener;

        this.checkAccount();

        this.msalBroadcastService.inProgress$
            .pipe(
                // Filtering for all interactions to be completed
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.checkAccount();
                this.getAdminPrivileges()
                this.fetchLanguages();
                if (this.settingsService.getLanguage().id != -1) {
                    this.languageListService.languageId = this.settingsService.getLanguage().id;
                }
                this.languageListService.fetchData();
                this.settingsService.initSession();

                if (window.location.hash == '#/meeting') {
                    this.selectedMenu.fill(false);
                    this.selectedMenu[1] = true;
                } else if (window.location.hash == '#/compare') {
                    this.selectedMenu.fill(false);
                    this.selectedMenu[2] = true;
                } else if (window.location.hash == '#/admin') {
                    this.selectedMenu.fill(false);
                    this.selectedMenu[3] = true;
                } else if (window.location.hash == '#/home') {
                    this.selectedMenu.fill(false);
                    this.selectedMenu[0] = true;
                } else {
                    this.selectedMenu.fill(false);
                    this.selectedMenu[1] = true;
                }


                if (this.getIsSession()) {
                    this.goToPage(1, 'session');
                }

                const meetingKeyIn = localStorage.getItem('meetingKey');
                const moderatorKeyIn = localStorage.getItem('moderatorKey');

                if ((meetingKeyIn && meetingKeyIn.length > 0) && (moderatorKeyIn && moderatorKeyIn.length > 0)) {
                    this.goToPage(1, 'session/' + meetingKeyIn + '/' + moderatorKeyIn);
                } else if (meetingKeyIn && meetingKeyIn.length > 0) {
                    this.goToPage(1, 'session/' + meetingKeyIn);
                }
            })

        this.menuIndexSubscr = this.msgService.selectedMenuSet$.subscribe(
            menuInx => {
                this.changeActiveMenu(menuInx);
            });
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(
            words => {
                this.languageItemList = words;
            });
        this.languageSubscr = this.languageListService.languagelist$.subscribe(
            words => {
                this.languageList = words;
            });
    }

    checkAccount(): void {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    toggleMobileSideMenu(): void {
        this.mobileNavOpen = !this.mobileNavOpen;
    }

    goToPage(pageIndex: number, pageName: string): void {
        this.changeActiveMenu(pageIndex);

        this.selectedMenu.fill(false);

        this.selectedMenu[pageIndex] = true;

        this.router.navigate([`/${pageName}`]);
    }

    getSelected(index: number) {
        return this.selectedMenu[index];
    }

    getAdminPrivileges(): void {
        if (!this.settingsService.setIsAdmin) return;

        this.adminService.getAdminPrivileges()
            .subscribe({
                next: (result: ReturnObject<LanguageAdmin[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarInfo(result.failureMessage);
                        return;
                    }
                    if (result.value.length != 0) {
                        this.settingsService.setLanguagePrivileges(result.value);
                    }
                },
                error: (err) => {
                    this.openSnackBarErr(err);
                }
            });
    }

    leaveSession(): void {
        this.settingsService.leaveSession();
        this.msgService.onSessionLeaveSet(true)
        this.selectedMenu.fill(false);
        this.selectedMenu[1] = true;
        this.router.navigate([`/meeting`]);
    }

    menuClassState(index: number): string {
        return this.activeMenu[index];
    }

    // -----------------------------------------------------------------------------------
    // Misc
    // -----------------------------------------------------------------------------------
    logout(): void {
        this.authService.logout();
    }

    loggedInUser() {
        return this.userService.getUser();
    }

    getPublishedLanguages() {
        return this.languageList.filter(x => x.published == true).sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
    }

    getCurrentLanguage(): string {
        const languageShortName = this.languageListService.getLanguage(this.languageListService.languageId).shortName.toUpperCase();
        return languageShortName != '' ? languageShortName : '...'
    }

    getFlag(shortName: string): string | undefined {
        const entry = this.languageFlags.find(item => item.shortName === shortName.toUpperCase());
        return entry ? entry.flag : undefined;
    }

    getJoinTitle() {
        if (this.getIsSession())
            return this.getLanguageItem("meeting");
        return this.getLanguageItem("navjoin")
    }

    getIsAdmin() {
        return this.settingsService.getIsAdmin();
    }

    getIsSession() {
        return this.settingsService.getIsSession();
        // checka här om det finns nån aktiv sessionStorage
        // om inte så göm leave session menyn och töm settings
    }
    openSnackBarInfo(message: string): void {
        this.toastVariant = 'information';
        this.toastHeader = 'Information';
        this.errMessage = message;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 7000
        );
    }
    openSnackBarErr(message: string): void {
        this.toastVariant = 'error';
        this.toastHeader = 'Error';
        this.errMessage = 'An unexpected error occurred. The error was: ' + message;;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 20000
        );
    }
    getLanguageItem(key: string): string {
        const languageItem = this.languageListService.getLanguageItem(key).textString;
        return languageItem != '' ? languageItem : '...'
    }

    fetchLanguageItems(languageId: number) {
        this.languageListService.languageId = languageId;
        this.languageListService.fetchData();

        this.settingsService.setLanguage(this.languageListService.getLanguage(languageId));
    }

    fetchLanguageItemsMobile(languageId: number) {
        this.languageListService.languageId = languageId;
        this.languageListService.fetchData();

        this.settingsService.setLanguage(this.languageListService.getLanguage(languageId));
        this.toggleMobileSideMenu();
    }

    fetchLanguages() {
        this.languageListService.getLanguageList();
    }
}
