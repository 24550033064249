import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReturnObject } from '../model/returnobject';
import { Language } from '../model/language';
import { LanguageItem } from '../model/languageitem';
import { LanguageService } from './language.service';

const SWEDISHID:number = 1;

@Injectable( {
    providedIn: 'root'
} )
export class LanguageListService {
    languageItemList: LanguageItem[] = [];
    languageList: Language[] = [];
    listIsValid: boolean = false;
    loading: boolean = false;

    private languagelistSource = new Subject<LanguageItem[]>();
    languageitemlist$ = this.languagelistSource.asObservable();
    private languageSource = new Subject<Language[]>();
    languagelist$ = this.languageSource.asObservable();

    private _listErrorMsg: string = '';
    public get listErrorMsg() {
        return this._listErrorMsg;
    }
    public set listErrorMsg(value: string) {
        this._listErrorMsg = value;
    }

    private _languageId : number = SWEDISHID;
    public set languageId(id : number) {
        this._languageId = id;
    }

    public get languageId() {
        return this._languageId;
    }

    constructor( private languageService: LanguageService ) {}

    public fetchData() {
        if ( this.languageItemList.length > 0 && this._languageId == this.languageItemList[0].languageId && this.listIsValid) {
            this.languagelistSource.next( this.languageItemList );
            return;
        }
        this.getLanguageListing();
    }

    public invalidateLanguage(){
        this.listIsValid = false;
    }

    getLanguageListing() {
        this.loading = true;
        this.languageService.getLanguageItemList(this._languageId)
            .subscribe({
                next: (result: ReturnObject<LanguageItem[]>) => {
                    this.loading = false;
                    if (result.isFailure) {
                        this._listErrorMsg = result.failureMessage;
                        this.languageItemList = [];
                        this.languagelistSource.next(this.languageItemList)
                        return;
                    }
                    this.languageItemList = result.value;
                    this.languagelistSource.next(this.languageItemList)
                    this.listIsValid = true;
                },
                error: (err) => {
                    this._listErrorMsg = err;
                    this.languagelistSource.next(this.languageItemList)
                    this.loading = false;
                }
            });

        // this.languageService.getLanguageItemList(this._languageId.toString())
        //     .subscribe(
        //         ( data: ReturnObject<LanguageItem[]> ) => {
        //             this.loading = false;
        //             if ( data.isFailure ) {
        //                 this._listErrorMsg = data.failureMessage;
        //                 this.languageitemlist = [];
        //                 this.languagelistSource.next( this.languageitemlist )
        //                 return;
        //             }
        //             this.languageitemlist = data.value;
        //             this.languagelistSource.next( this.languageitemlist )
        //             this.listIsValid = true;
        //         },
        //         err => {
        //             this._listErrorMsg = err;
        //             this.languagelistSource.next( this.languageitemlist )
        //             this.loading = false;
        //     } );
    }

    getLanguageList() {
        this.loading = true;

        this.languageService.getLanguageList()
            .subscribe({
                next: (result: ReturnObject<Language[]>) => {
                    this.loading = false;
                    if (result.isFailure) {
                        this._listErrorMsg = result.failureMessage;
                        this.languageList = [];
                        this.languageSource.next( this.languageList )
                        return;
                    }
                    this.languageList = result.value;
                    this.languageSource.next( this.languageList )
                },
                error: (err) => {
                    this.loading = false;
                    this._listErrorMsg = err;
                    this.languageSource.next(this.languageList)
                }
            });

        // this.languageService.getLanguageList()
        //     .subscribe( ( data: ReturnObject<Language[]> ) => {
        //         this.loading = false;
        //         if ( data.isFailure ) {
        //             this._listErrorMsg = data.failureMessage;
        //             this.languagelist = [];
        //             this.languageSource.next( this.languagelist )
        //             return;
        //         }
        //         this.languagelist = data.value;
        //         this.languageSource.next( this.languagelist )
        //     },
        //     err => {
        //         this.loading = false;
        //         this._listErrorMsg = err;
        //         this.languageSource.next( this.languagelist )
        //     } );
    }

    getLanguage(languageId: number): Language {
        const language = this.languageList.find(x => x.id == languageId);
        if (language != null) {
            return language;
        }
        return new Language();
    }

    getLanguageItem(key: string): LanguageItem {
        if (this.languageItemList.length != 0) {
            const languageItem = this.languageItemList.find(x => x.key == key);
            if (languageItem != null) {
                return languageItem;
            }
        }
        return new LanguageItem();
    }
}
