import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { errorCopy } from '../common/errorCopy';
import { LanguageListService } from '../service/languagelist.service';
import { LanguageItem } from '../model/languageitem';

import { MeetingItem } from '../model/meetingitem';
import { ReturnObject } from '../model/returnobject';
import { MeetingService } from '../service/meeting.service';

@Component({
    selector: 'app-meeting',
    templateUrl: './meeting.component.html',
    styleUrls: ['./meeting.component.css']
})
export class MeetingComponent implements OnInit, OnDestroy {
    BASE_URL: string = window.location.host.split('/')[0] + '/#/session/';

    displayedColumns: string[] = ['name', 'startMeetingButton', 'key', 'moderatorKey'];
    dataSource: any;

    static snackBarErrMsg: string = '';

    myMeetings: MeetingItem[] = []

    meetingName: string = "";
    generatedMeetingKey: string = "";
    generatedModeratorKey: string = "";

    languageSubscr: Subscription = new Subscription;
    languageItemList: LanguageItem[] = [];
    showSnackBar: boolean = true;
    showTooltipMobile: boolean = false;

    errMessage: string = '';
    toastVariant: string = '';
    toastHeader: string = '';
    toastSubHeader: string = ''

    constructor(
        private meetingService: MeetingService,
        private languageListService: LanguageListService,
        private router: Router,
        private errCopy: errorCopy) { }

    ngOnDestroy(): void {
        this.languageSubscr.unsubscribe();
    }

    ngOnInit(): void {
        if (window.location.host.split(':')[0] !== 'localhost') {
            this.BASE_URL = 'https://' + this.BASE_URL;
        }

        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(
            words => {
                this.languageItemList = words;
            });

        this.languageListService.fetchData();
        this.getMeetingsForUser();
    }

    getLanguageItem(key: string) {
        if (this.languageItemList.length != 0)
            return this.languageListService.getLanguageItem(key).textString;
        return "...";
    }

    getMeetingsForUser() {
        this.meetingService.getMeetingsForUser()
            .subscribe({
                next: (result: ReturnObject<MeetingItem[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        return;
                    }
                    this.myMeetings = result.value;
                    this.dataSource = this.myMeetings;
                },
                error: (err) => {
                    this.openSnackBarErr(err.message);
                }
            });
    }

    createMeeting() {
        this.meetingService.createMeeting(this.meetingName)
            .subscribe({
                next: (result: ReturnObject<MeetingItem>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        return;
                    }
                    this.myMeetings.push(result.value);
                    window.location.reload()
                },
                error: (err) => {
                    this.openSnackBarErr(err.message);
                }
            });
    }

    isCreateDisabled(): boolean {
        if (this.meetingName.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    startMeeting(meetingKey: string, moderatorKey: string): void {
        var link = "/session/" + meetingKey + "/" + moderatorKey;
        this.router.navigate([link]);
    }

    createMeetingLink(meetingKey: string): string {
        return this.BASE_URL + meetingKey;
    }

    createModeratorLink(meetingKey: string, moderatorKey: string): string {
        return this.BASE_URL + meetingKey + '/' + moderatorKey;
    }

    openSnackBarErr(message: string): void {
        this.toastVariant = 'error';
        this.toastHeader = 'Error';
        this.errMessage = 'An unexpected error occurred. The error was: ' + message;;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 20000
        );
    }
}
