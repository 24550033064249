<div style="display: flex; flex-direction: column; overflow-y: hidden;">
    <div style="min-height: 100%; display: flex; flex-direction: column; overflow-y: hidden;">
        <tds-header>
            <tds-header-hamburger
                aria-label="Open application drawer"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleMobileSideMenu()">
            </tds-header-hamburger>

            <tds-header-title style="cursor: pointer;" (click)="goToPage(1, 'meeting')">
                Work Balance {{getLanguageItem( 'dialoguetool' )}}
            </tds-header-title>

            <tds-header-item [selected]="getSelected(1) ? 'true' : null" *ngIf="loginDisplay && !getIsSession()">
                <button type="button" (click)="goToPage(1, 'meeting')">
                    {{getJoinTitle()}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(1) ? 'true' : null" *ngIf="getIsSession()">
                <button type="button" (click)="goToPage(1, 'session')">
                    {{getJoinTitle()}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(2) ? 'true' : null">
                <button type="button" (click)="goToPage(2, 'compare')">
                    {{getLanguageItem( 'navcompare' )}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(3) ? 'true' : null" *ngIf="getIsAdmin()">
                <button type="button" (click)="goToPage(3, 'admin')">
                    {{getLanguageItem( 'navadmin' )}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(0) ? 'true' : null">
                <button type="button" (click)="goToPage(0, 'home')">
                    {{getLanguageItem( 'navinfopage' )}}
                </button>
            </tds-header-item>

            <tds-header-item *ngIf="getIsSession()">
                <button type="button" (click)="leaveSession()">
                    {{getLanguageItem( 'navleave' )}}
                </button>
            </tds-header-item>

            <tds-header-dropdown slot="end" placement="end" no-dropdown-icon selected>
                <span slot="label"><img src="{{getFlag(getCurrentLanguage())}}" alt="flag"></span>
                <tds-header-dropdown-list>
                    <tds-header-dropdown-list-item [selected]="language.id == languageListService.languageId" *ngFor="let language of getPublishedLanguages()">
                        <a (click)="fetchLanguageItems(language.id)">
                            <img src="{{getFlag(language.shortName)}}" alt="{{language.shortName.toUpperCase()}}" width="50" height="auto">
                            <span class="wbt-spacing-left-8">{{language.fullName | titlecase}}</span>
                        </a>
                    </tds-header-dropdown-list-item>
                </tds-header-dropdown-list>
            </tds-header-dropdown>

            <tds-header-brand-symbol slot="end">
                <a aria-label="Scania - red gryphon on blue shield" href="https://www.scania.com/" target="_blank" rel="noopener"></a>
            </tds-header-brand-symbol>
        </tds-header>
    </div>
    <div style="display: flex; flex-grow: 1; overflow-y: hidden;">
        <tds-side-menu aria-label="Side menu" open="{{ mobileNavOpen }}">
            <tds-side-menu-overlay
                slot="overlay"
                (click)="toggleMobileSideMenu()">
            </tds-side-menu-overlay>

            <tds-side-menu-close-button
                slot="close-button"
                (click)="toggleMobileSideMenu()">
            </tds-side-menu-close-button>

            <tds-side-menu-item [selected]="getSelected(1) ? 'true' : null" *ngIf="loginDisplay && !getIsSession()">
                <a (click)="toggleMobileSideMenu(); goToPage(1, 'meeting')">
                    <tds-icon
                        name="edit"
                        size="24">
                    </tds-icon>
                    {{getJoinTitle()}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item [selected]="getSelected(1) ? 'true' : null" *ngIf="getIsSession()">
                <a (click)="toggleMobileSideMenu(); goToPage(1, 'session')">
                    <tds-icon
                        name="message"
                        size="24">
                    </tds-icon>
                    {{getJoinTitle()}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item [selected]="getSelected(2) ? 'true' : null">
                <a (click)="toggleMobileSideMenu(); goToPage(2, 'compare')">
                    <tds-icon
                        name="speedometer"
                        size="24">
                    </tds-icon>
                    {{getLanguageItem( 'navcompare' )}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item [selected]="getSelected(0) ? 'true' : null">
                <a (click)="toggleMobileSideMenu(); goToPage(0, 'home')">
                    <tds-icon
                        name="info"
                        size="24">
                    </tds-icon>
                    {{getLanguageItem( 'navinfopage' )}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-dropdown selected>
                <span slot="icon">
                    <img src="{{getFlag(getCurrentLanguage())}}" alt="flag" width="24" height="auto">
                </span>
                <span slot="label">
                    {{this.languageListService.getLanguage(this.languageListService.languageId).fullName | titlecase}}
                </span>
                <tds-side-menu-dropdown-list>
                    <tds-side-menu-dropdown-list-item *ngFor="let language of getPublishedLanguages()" [selected]="language.id == languageListService.languageId">
                        <button type="button" (click)="fetchLanguageItemsMobile(language.id)">
                            <img src="{{getFlag(language.shortName)}}" alt="{{language.shortName.toUpperCase()}}" width="50" height="auto">
                            <span class="wbt-spacing-left-8">{{language.fullName | titlecase}}</span>
                        </button>
                    </tds-side-menu-dropdown-list-item>
                </tds-side-menu-dropdown-list>
            </tds-side-menu-dropdown>

            <tds-side-menu-item *ngIf="getIsSession()">
                <a (click)="toggleMobileSideMenu(); leaveSession()">
                    <tds-icon
                        name="back"
                        size="24">
                    </tds-icon>
                    {{getLanguageItem( 'navleave' )}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-collapse-button slot="sticky-end"></tds-side-menu-collapse-button>
        </tds-side-menu>
    </div>
</div>

<section>
    <router-outlet></router-outlet>
</section>


<div class="wbt-spacing-bottom-16 wbt-spacing-right-16"
    *ngIf="errMessage != ''"
    style="position: fixed; bottom: 0px; right: 0;">
    <tds-toast
        variant="{{toastVariant}}"
        header="{{toastHeader}}"
        subheader="{{errMessage}}" >
    </tds-toast>
</div>
