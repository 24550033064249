import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReturnObject } from '../model/returnobject';
import { Language } from '../model/language';
import { LanguageItem } from '../model/languageitem';
import { AdminService } from '../service/admin.service';
import { InternalMsgService } from '../service/internal.msg.service';
import { LanguageService } from '../service/language.service';
import { LanguageListService } from '../service/languagelist.service';
import { SettingsService } from '../service/settings.service';
import { LanguageAdmin } from '../model/languageadmin';
import { errorCopy } from '../common/errorCopy';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
    static snackBarErrMsg: string = '';
    languageSubscr: Subscription = new Subscription();
    languageItemList: LanguageItem[] = [];
    languageList: Language[] = [];
    selected: number = -1;
    addTranslation: boolean = false;
    modifyLanguage: boolean = false;
    loading: boolean = false;
    newLanguage: Language = new Language();
    newLanguageItemList: LanguageItem[] = [];
    englishLanguageItemList: LanguageItem[] = [];
    translationLanguageItemList: LanguageItem[] = [];
    fullLanguageName: string = "";
    shortLanguageName: string = "";
    updateFullLanguageName: string = "";
    updateShortLanguageName: string = "";
    languagePublished: boolean = true;
    newHeader: string = "";
    newBody: string = "";
    panelOpenInfoTexts = false;
    panelOpenAppTexts = false;
    panelSessionQuestionsTexts = false;
    englishLanguage: number = 2;
    showConfirm: boolean = false;
    delType: string= '';
    dbId: number=-1;
    langTextChanged: boolean = false;
    showSnackBar: boolean = false;
    errMessage: string = '';
    toastVariant: string = '';
    toastHeader: string = '';
    toastSubHeader: string = ''

    constructor(private languageListService: LanguageListService,
        private msgService: InternalMsgService,
        private languageService: LanguageService,
        private adminService: AdminService,
        private settingsService: SettingsService,
        private errCopy: errorCopy) { }

    ngOnInit(): void {

        this.msgService.onSelectedMenuSet(3);
        this.initServices();
        this.selected = this.settingsService.getSelectedAdminLanguage();

        this.panelOpenInfoTexts = this.settingsService.getOpenedLanugagePanel() == "infotexts";
        this.panelOpenAppTexts = this.settingsService.getOpenedLanugagePanel() == "apptexts";
    }

    initServices() {
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(
            words => {
                this.languageItemList = words;
            });
        this.languageListService.fetchData();
        this.fetchLanguages();

        this.englishLanguageItemList = this.settingsService.getAdminLangEnglish();
        if (this.englishLanguageItemList.length == 0) {
            this.getLanguageList(this.englishLanguage, false);
        }

        this.getSelectionOnChange(this.englishLanguage);

        this.languageSubscr = this.languageListService.languagelist$.subscribe(
            words => {
                this.languageList = words;
                this.getSelection();
            });
    }


    openDialog(dbId: number,  delType: string) {
        this.delType = delType;
        this.dbId = dbId;
        this.showConfirm = true;
    }

    confirmDelete() {
        if (this.delType == "deleteLanguage") {
            if (this.settingsService.getSelectedAdminLanguage() == 2)
                this.openSnackBarInfo("Cannot delete the English language")
            else if (this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).published)
                this.openSnackBarInfo("Cannot delete a published language")
            else
                this.deleteLanguageFromDb();
        }
        else if (this.delType == "deleteLanguageItem") {
            this.getHeaderAndBodyToDelete(this.dbId);
        }
        this.showConfirm = false;

        window.location.reload();
    }

    getLanguageList(id: number, translation: boolean): void {
        this.loading = true;

        this.languageService.getLanguageItemList(id)
            .subscribe({
                next: (result: ReturnObject<LanguageItem[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    if (!translation) {
                        this.englishLanguageItemList = result.value;
                        this.settingsService.setAdminLangEnglish(this.englishLanguageItemList);
                    }
                    else if (translation) {
                        this.translationLanguageItemList = result.value;
                        this.settingsService.setAdminLangTranslate(this.translationLanguageItemList);
                        this.modifyLanguage = true;
                    }

                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }


    postItemToDb(item: LanguageItem): void {
        this.loading = true;
        this.adminService.postItemToDb(item)
            .subscribe({
                next: (result: ReturnObject<LanguageItem>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.loading = false;

                    if (item.id != -2) {
                        this.openSnackBarInfo("Text updated");
                    }
                    else {
                        this.openSnackBarInfo("Text created");
                    }

                    this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
                    this.languageListService.invalidateLanguage();
                    this.newHeader = "";
                    this.newBody = "";
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    postLanguageToDb(language: Language): void {
        this.loading = true;
        this.adminService.postLanguageToDb(language)
            .subscribe({
                next: (result: ReturnObject<Language>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.fetchLanguages();
                    if (language.id == -1) {
                        this.openSnackBarInfo("Language added to Database");
                        this.setAdminPrivileges(result.value);
                    }
                    else {
                        this.openSnackBarInfo("Language updated");
                    }
                    this.langTextChanged = false;
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    deleteLanguageFromDb(): void {
        this.loading = true;
        this.adminService.deleteLanguageFromDb(this.settingsService.getSelectedAdminLanguage())
            .subscribe({
                next: (result: ReturnObject<Language>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.fetchLanguages();
                    this.openSnackBarInfo("Language successfully deleted from DB");
                    this.settingsService.setSelectedAdminLanguage(0);
                    this.modifyLanguage = false;
                    this.loading = false;
                },
                error: (err: any) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    deleteLanguageItemFromDb(languageItemId: number): void {
        this.loading = true;
        this.adminService.deleteLanguageItemFromDb(languageItemId.toString())
            .subscribe({
                next: (result: ReturnObject<LanguageItem>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.openSnackBarInfo("Text successfully deleted from DB");
                    this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
                    this.languageListService.invalidateLanguage();
                    this.loading = false;
                },
                error: (err: any) => {
                    this.loading = false;
                    this.errMessage = err.message;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    getHeaderAndBodyToDelete(id: number): void {
        this.deleteLanguageItemFromDb(this.getTranslationLanguageItemById(id).id);
        this.deleteLanguageItemFromDb(this.getTranslationLanguageItemById(this.getInfopageItemBodyId(id)).id);
    }

    updateInfopageItem(itemId: number): void {
        //const id =Number.parseInt(event.currentTarget.id.toString().replace("button", ""));
        this.postItemToDb(this.getTranslationLanguageItemById(itemId));
        this.postItemToDb(this.getTranslationLanguageItemById(this.getInfopageItemBodyId(itemId)));
    }

    updateSessionpageQuestionsItem(itemId: number): void {
        this.postItemToDb(this.getTranslationLanguageItemById(itemId));
        this.postItemToDb(this.getTranslationLanguageItemById(this.getSessionpageQuestionsItemBodyId(itemId)));
    }

    setAdminPrivileges(language: Language): void {
        var adminList = this.settingsService.getLanguagePrivileges();
        var adminUser = new LanguageAdmin();
        adminUser.globalId = adminList[0].globalId;
        adminUser.languageId = language.id;
        adminList.push(adminUser);
    }

    getSelectionOnChange(languageId: number): void {
        this.settingsService.setSelectedAdminLanguage(languageId);
        this.modifyLanguage = false;
        this.langTextChanged = false;
        this.selected = languageId;
        this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
        this.languagePublished = this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).published;
    }

    getSelection(): void {
        this.settingsService.setSelectedAdminLanguage(this.selected);
        this.modifyLanguage = false;

        this.translationLanguageItemList = this.settingsService.getAdminLangTranslate();
        if (this.translationLanguageItemList == null) {
            this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
        } else {
            this.modifyLanguage = true;
        }

        this.languagePublished = this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).published;
    }

    getSelLanguagName(): string {
        if (this.languageList && this.languageList.length > 0 && this.settingsService.getSelectedAdminLanguage())
            return this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).fullName
        else
            return '';
    }

    setLanguageItem(event: { target: { id: string; value: string; }; }): void {
        const languageItem = this.getTranslationLanguageItemByKey(event.target.id);
        if (languageItem.id != -2) {
            languageItem.textString = event.target.value;
            this.postItemToDb(languageItem);
        }
        else {
            var newItem = new LanguageItem();
            newItem.languageId = this.settingsService.getSelectedAdminLanguage();
            newItem.textString = event.target.value;
            newItem.key = event.target.id;
            newItem.group = this.languageListService.getLanguageItem(event.target.id).group;
            this.postItemToDb(newItem);
        }
    }

    createLanguage(): void {
        if (this.languageList.find(x => x.fullName == this.fullLanguageName.toLowerCase()) || this.languageList.find(x => x.shortName == this.shortLanguageName.toLowerCase()))
            this.openSnackBarInfo("Language already in Database");
        else {
            var newLanguage = new Language();
            newLanguage.fullName = this.fullLanguageName.toLowerCase();
            newLanguage.shortName = this.shortLanguageName.toLowerCase();
            this.postLanguageToDb(newLanguage);
        }
    }

    updateLanguage(): void {
        var updatedLanguage = new Language();
        updatedLanguage.id = this.settingsService.getSelectedAdminLanguage();
        if (this.updateFullLanguageName != "") {
            updatedLanguage.fullName = this.updateFullLanguageName.toLowerCase();
            this.updateFullLanguageName = "";
        }
        else {
            updatedLanguage.fullName = this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).fullName;
        }
        if (this.updateShortLanguageName != "") {
            updatedLanguage.shortName = this.updateShortLanguageName.toLowerCase();
            this.updateShortLanguageName = "";
        }
        else {
            updatedLanguage.shortName = this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).shortName;
        }
        if (this.languagePublished && this.englishLanguageItemList.filter(x => x.group != "home").length != this.translationLanguageItemList.filter(x => x.group != "home").length) {
            this.openSnackBarInfo("Not all translations are completed");
        }
        else {
            updatedLanguage.published = this.languagePublished;
            this.postLanguageToDb(updatedLanguage);
        }
    }

    createInfopageItem(): void {
        var newHeader = new LanguageItem();
        newHeader.languageId = this.settingsService.getSelectedAdminLanguage();
        newHeader.textString = this.newHeader;
        newHeader.key = "header" + (this.translationLanguageItemList.filter(x => x.group == "home" && x.key.startsWith("header")).length + 1);
        newHeader.group = "home";
        this.postItemToDb(newHeader);

        var newBody = new LanguageItem();
        newBody.languageId = this.settingsService.getSelectedAdminLanguage();
        newBody.textString = this.newBody;
        newBody.key = "body" + (this.translationLanguageItemList.filter(x => x.group == "home" && x.key.startsWith("body")).length + 1);
        newBody.group = "home";
        this.postItemToDb(newBody);
    }


    // -----------------------------------------------------------------------------------
    // Misc
    // -----------------------------------------------------------------------------------

    openSnackBarInfo(message: string): void {
        this.toastVariant = 'information';
        this.toastHeader = 'Information';
        this.errMessage = message;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 7000
        );
    }

    openSnackBarErr(message: string): void {
        this.toastVariant = 'error';
        this.toastHeader = 'Error';
        this.errMessage = 'An unexpected error occurred. The error was: ' + message;;
        setTimeout( () => {
            this.errMessage = '' ;
            }, 20000
        );
    }

    setOpenedLanugagePanel(panelName: string, action: string): void {
        if (panelName == 'apptexts' && action == 'open') {
            this.settingsService.setOpenedLanugagePanel(panelName)
            this.panelOpenAppTexts = true;
            this.panelOpenInfoTexts = false;
            this.panelSessionQuestionsTexts = false;
            return;
        }
        if (panelName == 'infotexts' && action == 'open') {
            this.settingsService.setOpenedLanugagePanel(panelName)
            this.panelOpenAppTexts = false;
            this.panelOpenInfoTexts = true;
            this.panelSessionQuestionsTexts = false;
            return;
        }
        if (panelName == 'sessionquestionstexts' && action == 'open') {
            this.settingsService.setOpenedLanugagePanel(panelName)
            this.panelOpenAppTexts = false;
            this.panelOpenInfoTexts = false;
            this.panelSessionQuestionsTexts = true;
            return;
        }

        this.settingsService.setOpenedLanugagePanel('');
        this.panelOpenAppTexts = false;
        this.panelOpenInfoTexts = false;
        this.panelSessionQuestionsTexts = false;
    }

    closeAppTextPanel(): void {
        if (this.panelOpenAppTexts) this.settingsService.setOpenedLanugagePanel('');
        this.panelOpenAppTexts = false;
    }

    closeInfoTextPanel(): void {
        if (this.panelOpenInfoTexts) this.settingsService.setOpenedLanugagePanel('');
        this.panelOpenInfoTexts = false;
    }

    closeSessionQuestionsTextPanel(): void {
        if (this.panelSessionQuestionsTexts) this.settingsService.setOpenedLanugagePanel('');
        this.panelSessionQuestionsTexts = false;
    }

    getAdminLanguages(): Language[] {
        return this.languageList.filter(x => this.settingsService.getLanguagePrivileges().map(y => y.languageId).includes(x.id));
    }

    getHeader(id: number): string {
        return this.getTranslationLanguageItemById(id).textString;
    }

    setHeader(event: { target: { id: number; value: string; }; }): void {
        this.getTranslationLanguageItemById(event.target.id).textString = event.target.value;
    }

    setBody(event: { target: { id: number; value: string; }; }): void {
        this.getTranslationLanguageItemById(event.target.id).textString = event.target.value;
    }

    isCreateDisabled(): boolean {
        return this.fullLanguageName.length == 0 || this.shortLanguageName.length != 2;
    }

    getEnglishItemList(): LanguageItem[] {
        return this.englishLanguageItemList.filter(x => x.group != "home");
    }

    getInfopageItemHeaderList(): LanguageItem[] {
        var temp = this.translationLanguageItemList.filter(x => x.group == "home" && x.key.startsWith("header"));
        return temp;
    }

    getSessionpageQuestionsHeaderList(): LanguageItem[] {
        var temp = this.translationLanguageItemList.filter(x => x.group == "session" && x.key.startsWith("questionsmenu"));
        return temp;
    }

    getInfopageItemBody(key: string): string {
        var body = "body" + key.slice(6);
        return this.getTranslationLanguageItemByGroupAndKey("home", body).textString;
    }

    getSessionpageQuestionsItemBody(key: string): string {
        var body = "bodyfor" + key;
        return this.getTranslationLanguageItemByGroupAndKey("session", body).textString;
    }

    getInfopageItemBodyId(id: number): number {
        var key = this.getTranslationLanguageItemById(id).key;
        var body = "body" + key.slice(6);
        return this.getTranslationLanguageItemByGroupAndKey("home", body).id;
    }

    getSessionpageQuestionsItemBodyId(id: number): number {
        var key = this.getTranslationLanguageItemById(id).key;
        var body = "bodyfor" + key;
        return this.getTranslationLanguageItemByGroupAndKey("session", body).id;

    }

    getLanguageFullName(): string {
        return this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).fullName;
    }

    getLanguageShortName(): string {
        return this.languageListService.getLanguage(this.settingsService.getSelectedAdminLanguage()).shortName;
    }

    getLanguageItem(key: string): string {
        return this.languageListService.getLanguageItem(key).textString;
    }

    getTranslationItem(key: string): string {
        return this.getTranslationLanguageItemByKey(key).textString;
    }

    getTranslationLanguageItemByKey(key: string): LanguageItem {
        if (this.translationLanguageItemList.length != 0) {
            const languageItem = this.translationLanguageItemList.find(x => x.key == key);
            if (languageItem != null) {
                return languageItem;
            }
        }
        return new LanguageItem();
    }

    getTranslationLanguageItemById(id: number): LanguageItem {
        if (this.translationLanguageItemList.length != 0) {
            const languageItem = this.translationLanguageItemList.find(x => x.id == id);
            if (languageItem != null) {
                return languageItem;
            }
        }
        return new LanguageItem();
    }

    getTranslationLanguageItemByGroupAndKey(group: string, key: string): LanguageItem {
        if (this.translationLanguageItemList.length != 0) {
            const languageItem = this.translationLanguageItemList.find(x => x.group == group && x.key == key);
            if (languageItem != null) {
                return languageItem;
            }
        }
        return new LanguageItem();
    }

    fetchLanguages(): void {
        this.languageListService.getLanguageList();
    }
}
