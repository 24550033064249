import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { ReturnObject } from '../model/returnobject';
import { SessionUser } from '../model/sessionuser';
import { Observable } from 'rxjs';
@Injectable( {
    providedIn: 'root'
} )
export class SessionService {

    constructor( private api: APIService) {
    }

    postUserToDb(user: SessionUser) {
        return this.api.postAPI( `session/updateDB`, user);
    }

    deleteUserFromDb(detailId: number): any {
        return this.api.deleteByEndpointApi( `session/deleteuser/${detailId}` );
    }

    joinSession(meetingKey: string | null, moderatorKey: string) {
        return this.api.getAPI( `session/${meetingKey}/${moderatorKey}` );
    }

    addUserToSession(user: SessionUser) {
        return this.api.postAPI( `session/adduser`, user);
    }

    getSessionList(sessionId: number): Observable<ReturnObject<SessionUser[]>> {
        return this.api.getAPI( `session/${sessionId}` );
    }
}
