import { Injectable } from '@angular/core';
import { Language } from '../model/language';
import { LanguageAdmin } from '../model/languageadmin';
import { LanguageItem } from '../model/languageitem';
import { Account } from './group-guard.service';
import { groups } from '../auth-config';

@Injectable( {
    providedIn: 'root'
} )
export class SettingsService {
    // private language: Language = new Language();
    private languagePrivileges: LanguageAdmin[] = [];
    private isAdmin: boolean = false;
    private isSession: boolean = false;
    private isModerator: boolean = false;
    private meetingKey: string = '';
    private moderatorKey: string  = '';
    private sessionDate: string = '';
    private userId: string = '';
    private sessionId: number = -1;
    private selectedAdminLanguge: number = -1;
    private openedLanguagePanel: string = "";
    private englishLanguageItemList: LanguageItem[] = [];
    private translationLanguageItemList: LanguageItem[] = [];

    constructor ()   {}

    getSelectedAdminLanguage() {
      this.selectedAdminLanguge = this.getTLocalSetting<number>('selectedAdminLanguge', -1);
        if ( this.selectedAdminLanguge == -1 ) {
            this.selectedAdminLanguge = 2;
            this.setSelectedAdminLanguage( 2 );
        }
        return this.selectedAdminLanguge;
    }

    setSelectedAdminLanguage(languageId: number) {
        this.setTLocalSetting<number>( 'selectedAdminLanguge', languageId);
        this.selectedAdminLanguge = languageId;
    }

    getOpenedLanugagePanel(){
        return this.openedLanguagePanel;
    }

    setAdminLangEnglish(englishLanguageItemList: LanguageItem[]){
        this.englishLanguageItemList = englishLanguageItemList;
    }

    setAdminLangTranslate(translationLanguageItemList: LanguageItem[]){
        this.translationLanguageItemList = translationLanguageItemList;
    }

    getAdminLangEnglish(){
        return this.englishLanguageItemList;
    }

    getAdminLangTranslate(){
        return this.translationLanguageItemList;
    }

    setOpenedLanugagePanel(panelName: string ){
        this.openedLanguagePanel = panelName;
    }

    getLanguagePrivileges(){
        return this.languagePrivileges;
    }

    setLanguagePrivileges(languagePrivileges: LanguageAdmin[]){
        this.languagePrivileges = languagePrivileges;
    }

    getIsAdmin(){
        return this.isAdmin;
    }

    setIsAdmin(account: Account) {
        if (account.idTokenClaims?.groups?.includes(groups.groupAdmin)) this.isAdmin = true;
        else this.isAdmin = false;
    }

    getSessionId(){
        if ( !this.sessionId ) {
            this.sessionId = this.getTSessionSetting<number>('sessionId', -1);
        }
        return this.sessionId;
    }

    setSessionId(sessionId: number){
        this.sessionId = sessionId;
        if (sessionId) {
            sessionStorage.setItem( 'sessionId', JSON.stringify( sessionId ) );
        } else {
            sessionStorage.removeItem('sessionId');
        }
    }

    getUserId(){
        return this.userId;
    }

    setUserId(userId: string) {
        this.userId = userId;
    }

    getLanguage() {
        // if ( this.language.id == -1 ) {
        //   this.language = this.getTLocalSetting<Language>('language', new Language());
        // }
        // return this.language;
        return this.getTLocalSetting<Language>('language', new Language());
    }

    setLanguage( language: Language ) {
        //this.language = language;
        localStorage.setItem( 'language', JSON.stringify( language ) );
    }

    getSessionDate() {
        return this.sessionDate;
    }

    setSessionDate( sessionDate: string ) {
        this.sessionDate = sessionDate;
        if (sessionDate) {
            sessionStorage.setItem( 'sessionDate', JSON.stringify( sessionDate ) );
        } else {
            sessionStorage.removeItem('sessionDate');
        }
    }

    getIsSession() {
        var isSession = this.isSession && this.getIsSessionDate();
        return isSession;
    }

    setIsSession( isSession: boolean ) {
        this.isSession = isSession;
        if (isSession) {
            sessionStorage.setItem( 'isSession', JSON.stringify( isSession ) );
        } else {
            sessionStorage.removeItem('isSession');
        }
    }

    getIsModerator() {
        return this.isModerator && this.getIsSessionDate();
    }

    setIsModerator( isModerator: boolean ) {
        this.isModerator = isModerator;
        if (isModerator) {
             sessionStorage.setItem( 'isModerator', JSON.stringify( isModerator ) );
        } else {
            sessionStorage.removeItem('isModerator');
        }
    }

    getMeetingKey(): string {
        return this.meetingKey;
    }

    setMeetingKey( meetingKey: string | '') {
        this.meetingKey = meetingKey;
        if (meetingKey) {
            sessionStorage.setItem( 'meetingKey', JSON.stringify( meetingKey ) );
        } else {
            sessionStorage.removeItem('meetingKey');
        }
    }

    setMeetingKeyLocal( meetingKey: string ) {
        this.meetingKey = meetingKey;
        if (meetingKey) {
            localStorage.setItem('meetingKey', JSON.stringify(meetingKey));
        } else {
            localStorage.removeItem('meetingKey');
        }
    }

    getModeratorKey() {
        return this.moderatorKey;
    }

    setModeratorKey( moderatorKey: string ) {
        this.moderatorKey = moderatorKey;
        if (moderatorKey) {
            sessionStorage.setItem( 'moderatorKey', JSON.stringify( moderatorKey ) );
        } else {
            sessionStorage.removeItem('moderatorKey');
        }
    }

    setModeratorKeyLocal(moderatorKey: string ) {
        this.moderatorKey = moderatorKey;
        if (moderatorKey) {
            localStorage.setItem('moderatorKey', JSON.stringify(moderatorKey));
        } else {
            localStorage.removeItem('moderatorKey');
        }
    }

    private getIsSessionDate() {
        let sessionDate = this.getTSessionSetting<string>('sessionDate', '');
        return (sessionDate && sessionDate === this.getCurDateString())
    }

    initSession() {
        if (this.getIsSessionDate())
        {
          this.isSession = this.getTSessionSetting<boolean>('isSession', false);
          this.isModerator = this.getTSessionSetting<boolean>('isModerator', false);
          this.meetingKey = this.getTSessionSetting<string>('meetingKey', '');
          this.moderatorKey = this.getTSessionSetting<string>('moderatorKey', '');
          this.sessionDate = this.getTSessionSetting<string>('sessionDate', '');
        } else {
          this.setTSessionSetting<boolean>('isModerator', false);
          this.setTSessionSetting<boolean>('isSession', false);
          this.setTSessionSetting<string>('sessionDate', '');
          this.setTSessionSetting<string>('moderatorKey', '');
          this.setTSessionSetting<string>('meetingKey', '');
        }
    }



    private getCurDateString() : string {
        let today = new Date();
        var month =  (today.getMonth() + 1).toString().padStart(2, '0');
        var day =  today.getDate().toString().padStart(2, '0');

        return `${today.getFullYear().toString()}-${month}-${day}`;
    }

    getTSessionSetting<T>(settingKey: string, returnIfNotFound: T): T {
        const settingValue: string | null = sessionStorage.getItem(settingKey);
        if (settingValue !== null && settingValue !== 'null' && settingValue !== undefined && settingValue !== 'undefined') {
            return <T>JSON.parse(settingValue);
        }
        return returnIfNotFound;
    }

    setTSessionSetting<T>(settingKey: string, settingValue: T): void {
        sessionStorage.setItem(settingKey, JSON.stringify(settingValue));
    }

    getTLocalSetting<T>(settingKey: string, returnIfNotFound: T): T {
        const settingValue: string | null = localStorage.getItem(settingKey);
        if (settingValue !== null && settingValue !== 'null' && settingValue !== undefined && settingValue !== 'undefined') {
            return <T>JSON.parse(settingValue);
        }
        return returnIfNotFound;
    }

    setTLocalSetting<T>(settingKey: string, settingValue: T): void {
        localStorage.setItem(settingKey, JSON.stringify(settingValue));
    }

    leaveSession(){
        this.setIsSession(false);
        this.setIsModerator(false);
        this.setMeetingKey( '' );
        this.setModeratorKey( '' );
        this.setSessionDate( '' );
        this.setSessionId( -1 );
        this.setMeetingKeyLocal( '' );
        this.setModeratorKeyLocal( '' );
    }

    logout() {
        localStorage.removeItem( "menues" ); // local
        this.leaveSession();
    }
}
