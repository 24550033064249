<div id="content-desktop"
    style="margin-top:15px; text-align: center; display: flex; justify-content: center; height: 15px; opacity: 0.3;">
    <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"></div>
    <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4">
        <b *ngIf="loadingSession">{{getLanguageItem( 'sessionpageupdatetext' )}}</b><br>
        <img *ngIf="loadingSession" src='{{progressbarPath}}' width="100%" height="auto" title="progressBar">
    </div>
    <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"></div>
</div>

<div id="content-desktop" *ngIf="getIsSession()">
    <div class="tds-row" style="margin-bottom: 20px;padding-left: 40px;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-2 tds-col-xs-2"></div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"
            style="text-align: center;">
            <h2>{{getLanguageItem( 'meeting' )}}: {{meetingName}}</h2>
        </div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"></div>
    </div>

    <div class="tds-row" *ngIf="!getIsModerator()" style="margin-bottom: 20px">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-2 tds-col-xs-2"></div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"
            style="text-align: center;">
            {{getLanguageItem( 'numberofparticipants' )}}: {{getNumberOfParticipants()}}
        </div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"></div>
    </div>

    <div class="tds-row" *ngIf="getIsModerator()" style="margin-bottom: 20px">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-2 tds-col-xs-2">
            {{getLanguageItem( 'loggedinasmoderator' )}}
        </div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"
            style="text-align: right;">
            {{getLanguageItem( 'numberoflocalparticipants' )}}: {{getNumberOfLocalParticipants()}}
        </div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"
            style="text-align: left;">
            {{getLanguageItem( 'numberofremoteparticipants' )}}: {{getNumberOfRemoteParticipants()}}
        </div>
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-2 tds-col-xs-2"></div>
    </div>

    <!-- DRAG AND DROP COMPONENT -->
    <div class="tds-row">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-2 tds-col-xs-2"
            style="margin-top: 10%;">
            <!-- SMILEYS -->
            <div appDraggable>
                <div style="width: 100px;margin-left:-12px">
                    <svg height="75" width="100">
                        <g transform="scale(0.8) translate(50, 25)">
                            <title>{{getLanguageItem( 'excellentface' )}}</title>
                            <circle id="excellent" cx="32" cy="32" r="32" style="fill:#151843;stroke-width:4px;"
                                [ngStyle]="{'stroke': getDragFaceBorder()}" />
                            <path
                                d="M16.825,41.969c0,6.031 6.794,10.921 15.175,10.921c8.381,-0 15.175,-4.89 15.175,-10.921l-30.35,0Z"
                                style="fill:#fff;" />
                            <circle cx="20" cy="23" r="6" style="fill:#fff;" />
                            <circle cx="44" cy="23" r="6" style="fill:#fff;" />
                        </g>
                    </svg>
                </div>
                <div style="width: 100px; text-align: center;">
                    <span>{{getLanguageItem( 'excellentface' )}} </span>
                </div>
            </div>

            <div appDraggable>
                <div style="width: 100px;margin-left:-12px">
                    <svg height="75" width="100">
                        <g transform="scale(0.8) translate(50, 25)">
                            <title>{{getLanguageItem( 'goodface' )}}</title>
                            <circle id="good" cx="32" cy="32" r="32" style="fill:#9cb2a2;stroke-width:4px;"
                                [ngStyle]="{'stroke': getDragFaceBorder()}" />
                            <path
                                d="M17.241,43.302c0,4.556 6.599,8.255 14.726,8.255c8.128,-0 14.726,-3.699 14.726,-8.255c0,4.556 -6.598,8.255 -14.726,8.255c-8.127,-0 -14.726,-3.699 -14.726,-8.255Z"
                                style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                            <circle cx="20" cy="23" r="6" style="fill:#fff;" />
                            <circle cx="44" cy="23" r="6" style="fill:#fff;" />
                        </g>
                    </svg>
                </div>
                <div style="width: 100px; text-align: center;">
                    {{getLanguageItem( 'goodface' )}}
                </div>
            </div>

            <div appDraggable>
                <div style="width: 100px;margin-left:-12px">
                    <svg height="75" width="100">
                        <g transform="scale(0.8) translate(50, 25)">
                            <title>{{getLanguageItem( 'insufficientface' )}}</title>
                            <circle id="insufficient" cx="32" cy="32" r="32" style="fill:#dbbf8d;stroke-width:4px;"
                                [ngStyle]="{'stroke': getDragFaceBorder()}" />
                            <path d="M19.196,48.757l29.076,-9.884" style="fill:none;stroke:#fff;stroke-width:4px;" />
                            <circle cx="20" cy="23" r="6" style="fill:#fff;" />
                            <circle cx="44" cy="23" r="6" style="fill:#fff;" />
                        </g>
                    </svg>
                </div>
                <div style="width: 100px; text-align: center;">
                    {{getLanguageItem( 'insufficientface' )}}
                </div>
            </div>

            <div appDraggable>
                <div style="width: 100px;margin-left:-12px">
                    <svg height="75" width="100">
                        <g transform="scale(0.8) translate(50, 25)">
                            <title>{{getLanguageItem( 'poorface' )}}</title>
                            <circle id="poor" cx="32" cy="32" r="32" style="fill:#de0621;stroke-width:4px;"
                                [ngStyle]="{'stroke': getDragFaceBorder()}" />
                            <path
                                d="M45.219,47.959c0,-3.7 -5.903,-6.704 -13.175,-6.704c-7.272,-0 -13.176,3.004 -13.176,6.704c-0,-3.7 5.904,-6.704 13.176,-6.704c7.272,-0 13.175,3.004 13.175,6.704Z"
                                style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                            <circle cx="20" cy="23" r="6" style="fill:#fff;" />
                            <circle cx="44" cy="23" r="6" style="fill:#fff;" />
                        </g>
                    </svg>
                </div>
                <div style="width: 100px; text-align: center;">
                    {{getLanguageItem( 'poorface' )}}
                </div>
            </div>
        </div>

        <!-- CIRCLE COMPONENT -->
        <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-2 tds-col-xs-2">
            <svg viewBox="0 0 650 1100" width="750" height="1000" xmlns="http://www.w3.org/255/svg" appDroppable>
                <g transform="scale(0.5)">
                    <title>{{getTitle()}}</title>
                    <circle (mouseover)="setTitle($event)" (mouseout)="clearTitle()" style="fill:rgb(21, 24, 67);"
                        cx="716.085" cy="810.12" r="695.5" />

                    <path
                        d="M 20.627 804.382 L 712.563 804.382 L 712.563 815.858 L 20.627 815.858 C 20.59 812.77 20.57 812.487 20.58 809.847 C 20.589 807.244 20.58 807.15 20.627 804.382 Z"
                        style="fill: rgb(175, 185, 213);" />
                    <path
                        d="M 719.694 815.858 L 1411.63 815.858 L 1411.63 804.382 L 719.694 804.382 C 719.657 807.47 719.637 807.753 719.647 810.393 C 719.656 812.996 719.647 813.09 719.694 815.858 Z"
                        style="fill: rgb(175, 185, 213);" transform="matrix(-1, 0, 0, -1, 2131.274414, 1620.23999)" />
                    <path
                        d="M 715.512 0.084 C 718.194 0.046 720.069 -0.2 722.861 2.296 C 725.345 4.959 725.318 6.941 725.607 14.37 L 747.477 1564.276 L 715.372 1564.276 L 715.512 0.084 Z"
                        style="fill: rgb(199, 206, 229);" />
                    <path
                        d="M 688.858 1563.166 C 691.54 1563.204 693.415 1563.45 696.207 1560.954 C 698.691 1558.291 698.664 1556.309 698.953 1548.88 L 721.945 -1.026 L 689.018 -1.026 L 688.858 1563.166 Z"
                        style="fill: rgb(175, 185, 213);" transform="matrix(-1, 0, 0, -1, 1405.588989, 1563.112183)" />
                    <rect x="595.819" y="1559.695" width="241.266" height="35.402" style="fill: rgb(175, 185, 213);"
                        rx="9.48" ry="9.48" />
                    <rect x="690.691" y="1559.695" width="146.394" height="35.105" style="fill: rgb(199, 206, 229);"
                        rx="9.48" ry="9.48" />

                    <g transform="matrix(1, 0, 0, 1.026298, 1265.407593, -70.336105)">
                        <path
                            d="M 47.951 396.674 C 38.043 396.674 28.715 395.715 23.939 391.688 C 19.603 388.032 16.885 381.696 14.66 376.689 C 10.661 366.511 6.586 358.193 3.46 352.376 L 162.926 352.376 C 159.8 358.193 155.725 366.511 151.726 376.689 C 149.501 381.696 146.783 388.032 142.447 391.688 C 137.671 395.715 128.343 396.674 118.435 396.674 Z"
                            style="stroke-linejoin: round; stroke-linecap: round; stroke-width: 0px; stroke: rgb(175, 185, 213); fill: rgb(199, 206, 229);" />
                        <path
                            d="M 47.951 396.674 C 38.043 396.674 28.715 395.715 23.939 391.688 C 19.603 388.032 16.885 381.696 14.66 376.689 C 10.661 366.511 6.586 358.193 3.46 352.376 L 102.753 352.376 C 99.627 358.193 95.552 366.511 91.553 376.689 C 89.328 381.696 86.61 388.032 82.274 391.688 C 77.498 395.715 68.17 396.674 58.262 396.674 L 47.951 396.674 Z"
                            style="stroke-linejoin: round; stroke-linecap: round; stroke-width: 0px; stroke: rgb(175, 185, 213); fill: rgb(175, 185, 213);" />
                        <path d="M 280.545 937.062 L 355.453 1204.746 L 205.637 1204.746 L 280.545 937.062 Z"
                            style="fill: rgba(216, 216, 216, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 16px; stroke: rgb(150, 156, 182);"
                            transform="matrix(1, -0.000013, 0.000013, 1, -195.936844, -851.2453)" />
                        <line style="stroke-width: 16px; stroke-linecap: round; stroke: rgb(175, 185, 213);"
                            x1="160.745" y1="353.476" x2="89.88" y2="353.476" />
                        <line
                            style="stroke-width: 16px; stroke-linecap: round; fill: rgb(150, 156, 182); stroke: rgb(155, 165, 192);"
                            x1="82.52" y1="353.476" x2="10.503" y2="353.476" />
                    </g>

                    <path
                        d="M 47.951 396.674 C 38.043 396.674 28.715 395.715 23.939 391.688 C 19.603 388.032 16.885 381.696 14.66 376.689 C 10.661 366.511 6.586 358.193 3.46 352.376 L 162.926 352.376 C 159.8 358.193 155.725 366.511 151.726 376.689 C 149.501 381.696 146.783 388.032 142.447 391.688 C 137.671 395.715 128.343 396.674 118.435 396.674 Z"
                        style="stroke-linejoin: round; stroke-linecap: round; stroke-width: 0px; stroke: rgb(175, 185, 213); fill: rgb(199, 206, 229);" />
                    <path
                        d="M 47.951 396.674 C 38.043 396.674 28.715 395.715 23.939 391.688 C 19.603 388.032 16.885 381.696 14.66 376.689 C 10.661 366.511 6.586 358.193 3.46 352.376 L 102.753 352.376 C 99.627 358.193 95.552 366.511 91.553 376.689 C 89.328 381.696 86.61 388.032 82.274 391.688 C 77.498 395.715 68.17 396.674 58.262 396.674 L 47.951 396.674 Z"
                        style="stroke-linejoin: round; stroke-linecap: round; stroke-width: 0px; stroke: rgb(175, 185, 213); fill: rgb(175, 185, 213);" />
                    <path d="M 280.545 937.062 L 355.453 1204.746 L 205.637 1204.746 L 280.545 937.062 Z"
                        style="fill: rgba(216, 216, 216, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 16px; stroke: rgb(150, 156, 182);"
                        transform="matrix(1, -0.000013, 0.000013, 1, -195.936844, -851.2453)" />
                    <line style="stroke-width: 16px; stroke-linecap: round; stroke: rgb(175, 185, 213);" x1="160.745"
                        y1="353.476" x2="72.895" y2="353.476" />
                    <line style="stroke-width: 16px; stroke-linecap: round; stroke: rgb(155, 165, 192);" x1="80.749"
                        y1="353.792" x2="13.129" y2="353.792" />

                    <rect x="77.523" y="62.42" width="1301.521" height="24.403" style="fill: rgb(115, 116, 139);"
                        rx="12.201" ry="12.201"
                        transform="matrix(0.998944, -0.04595, 0.04595, 0.998944, -14.161609, 16.940481)" />

                    <circle style="fill: rgb(150, 156, 182);" cx="716.793" cy="57.932" r="34.236" />
                    <circle style="fill: rgb(175, 185, 213);" cx="716.793" cy="57.932" r="14.341" />
                    <circle style="fill: rgb(175, 185, 213);" cx="84.487" cy="86.683" r="7.085" />
                    <circle style="fill: rgb(175, 185, 213);" cx="1349.311" cy="27.363" r="7.085" />

                    <g *ngIf="getLabelLength(getLanguageItem( 'manageability' ))<18">
                        <text style="text-anchor:end;" class="svgheaderfont" x="660" y="410">{{getLanguageItem(
                            'manageability' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageability' ))>17">
                        <text style="text-anchor:end;" class="svgheaderfont" x="660"
                            y="390">{{getFirstHalfOfLabel(getLanguageItem( 'manageability' ))}}</text>
                        <text style="text-anchor:end;" class="svgheaderfont" x="660"
                            y="430">{{getRestOfLabel(getLanguageItem( 'manageability' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow1' ))<25">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="510">{{getLanguageItem(
                            'manageabilityrow1' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow1' ))>24">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="490">{{getFirstHalfOfLabel(getLanguageItem( 'manageabilityrow1' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660" y="530">{{getRestOfLabel(getLanguageItem(
                            'manageabilityrow1' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow2' ))<30">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="590">{{getLanguageItem(
                            'manageabilityrow2' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow2' ))>29">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="570">{{getFirstHalfOfLabel(getLanguageItem( 'manageabilityrow2' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660" y="610">{{getRestOfLabel(getLanguageItem(
                            'manageabilityrow2' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow3' ))<33">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="670">{{getLanguageItem(
                            'manageabilityrow3' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow3' ))>32">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="650">{{getFirstHalfOfLabel(getLanguageItem( 'manageabilityrow3' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660" y="690">{{getRestOfLabel(getLanguageItem(
                            'manageabilityrow3' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow4' ))<34">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="750">{{getLanguageItem(
                            'manageabilityrow4' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'manageabilityrow4' ))>33">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="730">{{getFirstHalfOfLabel(getLanguageItem( 'manageabilityrow4' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660" y="770">{{getRestOfLabel(getLanguageItem(
                            'manageabilityrow4' ))}}</text>
                    </g>


                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulness' ))<18">
                        <text style="text-anchor:end;" class="svgheaderfont" x="660" y="900">{{getLanguageItem(
                            'meaningfulness' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulness' ))>17">
                        <text style="text-anchor:end;" class="svgheaderfont" x="660"
                            y="880">{{getFirstHalfOfLabel(getLanguageItem( 'meaningfulness' ))}}</text>
                        <text style="text-anchor:end;" class="svgheaderfont" x="660"
                            y="920">{{getRestOfLabel(getLanguageItem( 'meaningfulness' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow1' ))<34">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="1010">{{getLanguageItem(
                            'meaningfulnessrow1' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow1' ))>33">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="990">{{getFirstHalfOfLabel(getLanguageItem( 'meaningfulnessrow1' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1030">{{getRestOfLabel(getLanguageItem( 'meaningfulnessrow1' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow2' ))<33">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="1090">{{getLanguageItem(
                            'meaningfulnessrow2' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow2' ))>32">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1070">{{getFirstHalfOfLabel(getLanguageItem( 'meaningfulnessrow2' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1110">{{getRestOfLabel(getLanguageItem( 'meaningfulnessrow2' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow3' ))<30">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="1170">{{getLanguageItem(
                            'meaningfulnessrow3' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow3' ))>29">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1150">{{getFirstHalfOfLabel(getLanguageItem( 'meaningfulnessrow3' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1190">{{getRestOfLabel(getLanguageItem( 'meaningfulnessrow3' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow4' ))<25">
                        <text style="text-anchor:end;" class="svgfont" x="660" y="1250">{{getLanguageItem(
                            'meaningfulnessrow4' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'meaningfulnessrow4' ))>24">
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1230">{{getFirstHalfOfLabel(getLanguageItem( 'meaningfulnessrow4' ))}}</text>
                        <text style="text-anchor:end;" class="svgfont" x="660"
                            y="1270">{{getRestOfLabel(getLanguageItem( 'meaningfulnessrow4' ))}}</text>
                    </g>


                    <g *ngIf="getLabelLength(getLanguageItem( 'recovery' ))<18">
                        <text style="text-anchor:start;" class="svgheaderfont" x="770" y="900">{{getLanguageItem(
                            'recovery' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recovery' ))>17">
                        <text style="text-anchor:start;" class="svgheaderfont" x="770"
                            y="880">{{getFirstHalfOfLabel(getLanguageItem( 'recovery' ))}}</text>
                        <text style="text-anchor:start;" class="svgheaderfont" x="770"
                            y="920">{{getRestOfLabel(getLanguageItem( 'recovery' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow1' ))<34">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="1010">{{getLanguageItem(
                            'recoveryrow1' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow1' ))>33">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="990">{{getFirstHalfOfLabel(getLanguageItem( 'recoveryrow1' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1030">{{getRestOfLabel(getLanguageItem( 'recoveryrow1' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow2' ))<33">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="1090">{{getLanguageItem(
                            'recoveryrow2' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow2' ))>32">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1070">{{getFirstHalfOfLabel(getLanguageItem( 'recoveryrow2' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1100">{{getRestOfLabel(getLanguageItem( 'recoveryrow2' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow3' ))<30">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="1170">{{getLanguageItem(
                            'recoveryrow3' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow3' ))>29">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1150">{{getFirstHalfOfLabel(getLanguageItem( 'recoveryrow3' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1190">{{getRestOfLabel(getLanguageItem( 'recoveryrow3' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow4' ))<25">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="1250">{{getLanguageItem(
                            'recoveryrow4' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'recoveryrow4' ))>24">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1230">{{getFirstHalfOfLabel(getLanguageItem( 'recoveryrow4' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="1270">{{getRestOfLabel(getLanguageItem( 'recoveryrow4' ))}}</text>
                    </g>


                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibility' ))<18">
                        <text style="text-anchor:start;" class="svgheaderfont" x="770" y="410">{{getLanguageItem(
                            'comprehensibility' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibility' ))>17">
                        <text style="text-anchor:start;" class="svgheaderfont" x="770"
                            y="390">{{getFirstHalfOfLabel(getLanguageItem( 'comprehensibility' ))}}</text>
                        <text style="text-anchor:start;" class="svgheaderfont" x="770"
                            y="430">{{getRestOfLabel(getLanguageItem( 'comprehensibility' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow1' ))<25">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="510">{{getLanguageItem(
                            'comprehensibilityrow1' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow1' ))>24">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="490">{{getFirstHalfOfLabel(getLanguageItem( 'comprehensibilityrow1' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="530">{{getRestOfLabel(getLanguageItem( 'comprehensibilityrow1' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow2' ))<30">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="590">{{getLanguageItem(
                            'comprehensibilityrow2' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow2' ))>29">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="570">{{getFirstHalfOfLabel(getLanguageItem( 'comprehensibilityrow2' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="610">{{getRestOfLabel(getLanguageItem( 'comprehensibilityrow2' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow3' ))<33">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="670">{{getLanguageItem(
                            'comprehensibilityrow3' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow3' ))>32">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="650">{{getFirstHalfOfLabel(getLanguageItem( 'comprehensibilityrow3' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="690">{{getRestOfLabel(getLanguageItem( 'comprehensibilityrow3' ))}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow4' ))<34">
                        <text style="text-anchor:start;" class="svgfont" x="770" y="750">{{getLanguageItem(
                            'comprehensibilityrow4' )}}</text>
                    </g>
                    <g *ngIf="getLabelLength(getLanguageItem( 'comprehensibilityrow4' ))>33">
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="730">{{getFirstHalfOfLabel(getLanguageItem( 'comprehensibilityrow4' ))}}</text>
                        <text style="text-anchor:start;" class="svgfont" x="770"
                            y="770">{{getRestOfLabel(getLanguageItem( 'comprehensibilityrow4' ))}}</text>
                    </g>

                    <g attr.transform="scale(1.5625) translate({{participant.handlingX}}, {{participant.handlingY}})"
                        (click)="deleteDroppedFace($event)" *ngFor="let participant of participants"
                        [ngStyle]="{'visibility':participant.handlingValue ? 'visible' : 'hidden'}">
                        <circle attr.id="{{participant.detailId}}" cx="32" cy="32" r="32"
                            [ngStyle]="getFaceColor(participant.userKey, participant.handlingValue, participant.isModeratorRecord)" />
                        <path *ngIf="participant.handlingValue === 'excellent'" attr.id="{{participant.detailId}}"
                            d="M16.825,41.969c0,6.031 6.794,10.921 15.175,10.921c8.381,-0 15.175,-4.89 15.175,-10.921l-30.35,0Z"
                            style="fill:#fff;" />
                        <path *ngIf="participant.handlingValue === 'good'" attr.id="{{participant.detailId}}"
                            d="M17.241,43.302c0,4.556 6.599,8.255 14.726,8.255c8.128,-0 14.726,-3.699 14.726,-8.255c0,4.556 -6.598,8.255 -14.726,8.255c-8.127,-0 -14.726,-3.699 -14.726,-8.255Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.handlingValue === 'insufficient'" attr.id="{{participant.detailId}}"
                            d="M19.196,48.757l29.076,-9.884" style="fill:none;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.handlingValue === 'poor'" attr.id="{{participant.detailId}}"
                            d="M45.219,47.959c0,-3.7 -5.903,-6.704 -13.175,-6.704c-7.272,-0 -13.176,3.004 -13.176,6.704c-0,-3.7 5.904,-6.704 13.176,-6.704c7.272,-0 13.175,3.004 13.175,6.704Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <circle attr.id="{{participant.detailId}}" cx="20" cy="23" r="6" style="fill:#fff;" />
                        <circle attr.id="{{participant.detailId}}" cx="44" cy="23" r="6" style="fill:#fff;" />
                    </g>
                    <g attr.transform="scale(1.5625) translate({{participant.meaningfulnessX}}, {{participant.meaningfulnessY}})"
                        (click)="deleteDroppedFace($event)" *ngFor="let participant of participants"
                        [ngStyle]="{'visibility':participant.meaningfulnessValue ? 'visible' : 'hidden'}">
                        <circle attr.id="{{participant.detailId}}" cx="32" cy="32" r="32"
                            [ngStyle]="getFaceColor(participant.userKey, participant.meaningfulnessValue, participant.isModeratorRecord)" />
                        <path *ngIf="participant.meaningfulnessValue === 'excellent'" attr.id="{{participant.detailId}}"
                            d="M16.825,41.969c0,6.031 6.794,10.921 15.175,10.921c8.381,-0 15.175,-4.89 15.175,-10.921l-30.35,0Z"
                            style="fill:#fff;" />
                        <path *ngIf="participant.meaningfulnessValue === 'good'" attr.id="{{participant.detailId}}"
                            d="M17.241,43.302c0,4.556 6.599,8.255 14.726,8.255c8.128,-0 14.726,-3.699 14.726,-8.255c0,4.556 -6.598,8.255 -14.726,8.255c-8.127,-0 -14.726,-3.699 -14.726,-8.255Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.meaningfulnessValue === 'insufficient'"
                            attr.id="{{participant.detailId}}" d="M19.196,48.757l29.076,-9.884"
                            style="fill:none;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.meaningfulnessValue === 'poor'" attr.id="{{participant.detailId}}"
                            d="M45.219,47.959c0,-3.7 -5.903,-6.704 -13.175,-6.704c-7.272,-0 -13.176,3.004 -13.176,6.704c-0,-3.7 5.904,-6.704 13.176,-6.704c7.272,-0 13.175,3.004 13.175,6.704Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <circle attr.id="{{participant.detailId}}" cx="20" cy="23" r="6" style="fill:#fff;" />
                        <circle attr.id="{{participant.detailId}}" cx="44" cy="23" r="6" style="fill:#fff;" />
                    </g>
                    <g attr.transform="scale(1.5625) translate({{participant.understandableX}}, {{participant.understandableY}})"
                        (click)="deleteDroppedFace($event)" *ngFor="let participant of participants"
                        [ngStyle]="{'visibility':participant.understandableValue ? 'visible' : 'hidden'}">
                        <circle attr.id="{{participant.detailId}}" cx="32" cy="32" r="32"
                            [ngStyle]="getFaceColor(participant.userKey, participant.understandableValue, participant.isModeratorRecord)" />
                        <path *ngIf="participant.understandableValue === 'excellent'" attr.id="{{participant.detailId}}"
                            d="M16.825,41.969c0,6.031 6.794,10.921 15.175,10.921c8.381,-0 15.175,-4.89 15.175,-10.921l-30.35,0Z"
                            style="fill:#fff;" />
                        <path *ngIf="participant.understandableValue === 'good'" attr.id="{{participant.detailId}}"
                            d="M17.241,43.302c0,4.556 6.599,8.255 14.726,8.255c8.128,-0 14.726,-3.699 14.726,-8.255c0,4.556 -6.598,8.255 -14.726,8.255c-8.127,-0 -14.726,-3.699 -14.726,-8.255Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.understandableValue === 'insufficient'"
                            attr.id="{{participant.detailId}}" d="M19.196,48.757l29.076,-9.884"
                            style="fill:none;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.understandableValue === 'poor'" attr.id="{{participant.detailId}}"
                            d="M45.219,47.959c0,-3.7 -5.903,-6.704 -13.175,-6.704c-7.272,-0 -13.176,3.004 -13.176,6.704c-0,-3.7 5.904,-6.704 13.176,-6.704c7.272,-0 13.175,3.004 13.175,6.704Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <circle attr.id="{{participant.detailId}}" cx="20" cy="23" r="6" style="fill:#fff;" />
                        <circle attr.id="{{participant.detailId}}" cx="44" cy="23" r="6" style="fill:#fff;" />
                    </g>
                    <g attr.transform="scale(1.5625) translate({{participant.recoverX}}, {{participant.recoverY}})"
                        (click)="deleteDroppedFace($event)" *ngFor="let participant of participants"
                        [ngStyle]="{'visibility':participant.recoverValue ? 'visible' : 'hidden'}">
                        <circle attr.id="{{participant.detailId}}" cx="32" cy="32" r="32"
                            [ngStyle]="getFaceColor(participant.userKey, participant.recoverValue, participant.isModeratorRecord)" />
                        <path *ngIf="participant.recoverValue === 'excellent'" attr.id="{{participant.detailId}}"
                            d="M16.825,41.969c0,6.031 6.794,10.921 15.175,10.921c8.381,-0 15.175,-4.89 15.175,-10.921l-30.35,0Z"
                            style="fill:#fff;" />
                        <path *ngIf="participant.recoverValue === 'good'" attr.id="{{participant.detailId}}"
                            d="M17.241,43.302c0,4.556 6.599,8.255 14.726,8.255c8.128,-0 14.726,-3.699 14.726,-8.255c0,4.556 -6.598,8.255 -14.726,8.255c-8.127,-0 -14.726,-3.699 -14.726,-8.255Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.recoverValue === 'insufficient'" attr.id="{{participant.detailId}}"
                            d="M19.196,48.757l29.076,-9.884" style="fill:none;stroke:#fff;stroke-width:4px;" />
                        <path *ngIf="participant.recoverValue === 'poor'" attr.id="{{participant.detailId}}"
                            d="M45.219,47.959c0,-3.7 -5.903,-6.704 -13.175,-6.704c-7.272,-0 -13.176,3.004 -13.176,6.704c-0,-3.7 5.904,-6.704 13.176,-6.704c7.272,-0 13.175,3.004 13.175,6.704Z"
                            style="fill:#151843;stroke:#fff;stroke-width:4px;" />
                        <circle attr.id="{{participant.detailId}}" cx="20" cy="23" r="6" style="fill:#fff;" />
                        <circle attr.id="{{participant.detailId}}" cx="44" cy="23" r="6" style="fill:#fff;" />
                    </g>
                </g>
            </svg>
        </div>

        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-4"
            style="padding-left: 125px;">
            <div style="text-align: center;">
                <h4>{{getLanguageItem( 'questionsheader' )}}</h4>
            </div>

            <tds-accordion>
                <tds-accordion-item>
                    <div slot="header">{{getLanguageItem( 'questionsmenu1' )}}</div>
                    <p style="white-space: pre-line">{{getLanguageItem( 'bodyforquestionsmenu1' )}}</p>
                </tds-accordion-item>

                <tds-accordion-item>
                    <div slot="header">{{getLanguageItem( 'questionsmenu2' )}}</div>
                    <p style="white-space: pre-line">{{getLanguageItem( 'bodyforquestionsmenu2' )}}</p>
                </tds-accordion-item>

                <tds-accordion-item>
                    <div slot="header">{{getLanguageItem( 'questionsmenu3' )}}</div>
                    <p style="white-space: pre-line">{{getLanguageItem( 'bodyforquestionsmenu3' )}}</p>
                </tds-accordion-item>

                <tds-accordion-item>
                    <div slot="header">{{getLanguageItem( 'questionsmenu4' )}}</div>
                    <p style="white-space: pre-line">{{getLanguageItem( 'bodyforquestionsmenu4' )}}</p>
                </tds-accordion-item>
            </tds-accordion>
        </div>
    </div>
</div>


<div id="content-mobile" *ngIf="getIsSession()">
    <div *ngIf="isScrollable()" style="position: fixed;bottom: 10px;right: 20px;">
        <tds-icon name="arrow_down" size="24px"></tds-icon>
    </div>
    <div style="text-align: center;">
        <h4 style="font-size:5vw;">{{getLanguageItem( 'meeting' )}}</h4>
    </div>
    <div *ngIf="getIsModerator()" style="text-align: center;">{{getLanguageItem( 'loggedinasmoderator' )}}</div>

    <div>
        <div *ngIf="!getIsModerator()" style="text-align: center">
            {{getLanguageItem( 'numberofparticipants' )}}: {{getNumberOfParticipants()}}
        </div>
        <div id="content-mobile-portrait" *ngIf="getIsModerator()" style="text-align: center;padding-top: 30px;">
            {{getLanguageItem( 'numberoflocalparticipants' )}}: {{getNumberOfLocalParticipants()}}
        </div>
        <div id="content-mobile-portrait" *ngIf="getIsModerator()" style="text-align: center;">
            {{getLanguageItem( 'numberofremoteparticipants' )}}: {{getNumberOfRemoteParticipants()}}
        </div>
        <div id="content-mobile-landscape">
            <div *ngIf="getIsModerator()" style="text-align: center;padding-top: 30px;display: inline-block;width: 50%;">
                {{getLanguageItem( 'numberoflocalparticipants' )}}: {{getNumberOfLocalParticipants()}}
            </div>
            <div *ngIf="getIsModerator()" style="text-align: center;display: inline-block;width: 50%;">
                {{getLanguageItem( 'numberofremoteparticipants' )}}: {{getNumberOfRemoteParticipants()}}
            </div>
        </div>
    </div>

    <div style="padding-top: 10px;">

        <tds-accordion>
            <tds-accordion-item *ngIf="!getIsModerator()" padding-reset (tdsToggle)="panelMyFacesOpen = !panelMyFacesOpen" [expanded]="panelMyFacesOpen">
                <div slot="header">{{getLanguageItem( 'myfaces' )}} - {{panelMyFacesOpen ? getLanguageItem( 'clicktoclose' ) : getLanguageItem( 'clicktoopen' )}}</div>

                <div id="content-mobile-portrait">
                    <div *ngFor="let section of sections" class="wbt-spacing-bottom-8" style="display: flex; justify-content: center">
                        <div class="wbt-spacing-bottom-8 wbt-spacing-right-8">
                            <tds-icon id="icon{{section}}" name="info" size="24px"></tds-icon>
                            <tds-tooltip selector="#icon{{section}}" text="{{getTitleText( section )}}"></tds-tooltip>
                            {{getLanguageItem( section )}}
                        </div>
                        <div>
                            <img *ngFor="let face of faces.reverse()" (click)="setFaceMobile(section, face.value)" class="wbt-spacing-right-8 img-filter" [ngClass]="{'active': face.value == getEquivalentFieldNameFromSection(section)}" src='{{face.myImg}}' alt="face" width="40px" height="40px">
                        </div>
                    </div>
                </div>

                <div id="content-mobile-landscape">
                    <div *ngFor="let section of sections" class="tds-row wbt-spacing-bottom-8" style="display: flex; justify-content: center">
                        <div class="wbt-spacing-bottom-8 wbt-spacing-right-16">
                            <tds-icon id="icon{{section}}" name="info" size="24px"></tds-icon>
                            <tds-tooltip selector="#icon{{section}}" text="{{getTitleText( section )}}"></tds-tooltip>
                            {{getLanguageItem( section )}}
                        </div>
                        <div>
                            <img *ngFor="let face of faces.reverse()" (click)="setFaceMobile(section, face.value)" class="wbt-spacing-right-8 img-filter" [ngClass]="{'active': face.value == getEquivalentFieldNameFromSection(section)}" src='{{face.myImg}}' alt="face" width="40px" height="40px">
                        </div>
                    </div>
                </div>
            </tds-accordion-item>

            <tds-accordion-item *ngIf="getIsModerator()" padding-reset (tdsToggle)="panelMyFacesOpen = !panelMyFacesOpen" [expanded]="panelMyFacesOpen">
                <div slot="header">{{getLanguageItem( 'myfaces' )}} - {{panelMyFacesOpen ? getLanguageItem( 'clicktoclose' ) : getLanguageItem( 'clicktoopen' )}}</div>

                <tds-dropdown name="dropdown" label-position="outside" size="lg" default-value="manageability" open-direction="auto">
                    <tds-dropdown-option *ngFor="let section of sections" (click)="selectedSection=section" value="{{section}}">
                        {{getLanguageItem( section )}}
                    </tds-dropdown-option>
                </tds-dropdown>

                <div id="content-mobile-portrait" *ngFor="let section of sections" label="{{getLanguageItem( section )}}">
                    <div *ngIf="selectedSection==section">
                        <div style="text-align: center;font-size:5vw;padding-top: 10px;">
                            {{getLanguageItem( section )}}
                            <tds-icon id="topDiv" name="info" size="24px" class="wbt-spacing-bottom-8"></tds-icon>
                            <tds-tooltip selector="#topDiv" text="{{getTitleText(section)}}"></tds-tooltip>
                        </div>

                        <div *ngFor="let face of faces">
                            <div style="text-align: center;padding-top: 10px;">
                                <img src='{{face.modImg}}' width="40px" height="40px" alt="" style="border-radius: 50%;">
                            </div>

                            <div style="text-align: center;">
                                <tds-button type="button" variant="ghost" size="sm" text=""
                                    (click)="deleteFaceMobile(section, face.value)">
                                    <tds-icon slot="icon" size="20px" name="minus"></tds-icon>
                                </tds-button>

                                <span>
                                    {{getModeratorFacesMobile(face.value, section)}}
                                </span>

                                <tds-button type="button" variant="ghost" size="sm" text=""
                                    (click)="setFaceModerator(face.value, section)">
                                    <tds-icon slot="icon" size="20px" name="plus"></tds-icon>
                                </tds-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="content-mobile-landscape" *ngFor="let section of sections" label="{{getLanguageItem( section )}}">
                    <div *ngIf="selectedSection==section">
                        <div style="text-align: center;font-size:3vw;padding-top: 10px;" class="wbt-spacing-bottom-16">
                            {{getLanguageItem( section )}}
                            <tds-icon slot="icon" id="topDivs" name="info" size="32px" class="wbt-spacing-bottom-8"></tds-icon>
                            <tds-tooltip placement="bottom" trigger="click" selector="#topDivs"
                                text="{{getTitleText(section)}}"></tds-tooltip>

                        </div>
                        <div *ngFor="let face of faces" style="display: inline-block;width: 25%;">
                            <div class="tds-row">
                                <div classs="tds-col-xs-12" style="text-align: center;padding-top: 10px;width: 100%;">
                                    <img src='{{face.modImg}}' width="40px" height="40px" alt="" style="border-radius: 50%;">
                                </div>
                            </div>
                            <div class="tds-row">
                                <div class="tds-col-xs-4 wbt" style="text-align: right;">
                                    <tds-button type="button" variant="ghost" size="sm" text=""
                                        (click)="deleteFaceMobile(section, face.value)">
                                        <tds-icon slot="icon" size="20px" name="minus"></tds-icon>
                                    </tds-button>
                                </div>
                                <div class="tds-col-xs-4" style="text-align: center">
                                    {{getModeratorFacesMobile(face.value, section)}}
                                </div>
                                <div class="tds-col-xs-4" style="text-align: left;">
                                    <tds-button type="button" variant="ghost" size="sm" text=""
                                        (click)="setFaceModerator(face.value, section)">
                                        <tds-icon slot="icon" size="20px" name="plus"></tds-icon>
                                    </tds-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </tds-accordion-item>
        </tds-accordion>
    </div>

    <div id="content-mobile-portrait">
        <div *ngFor="let section of sections">
            <div style="text-align: center;padding-top: 10px;">
                <h5 style="font-size:5vw;">{{getLanguageItem( section )}}</h5>
            </div>
            <div style="text-align: center;padding-top: 10px;">
                <div style="width: 10%;display: inline-block"></div>
                <div style="width: 80%;display: inline-block">
                    <div style="text-align: center;display: inline;" *ngFor="let participant of participants">
                        <img title="s" *ngIf="participant.getSectionValue(section) === 'excellent'" src='{{faces[0].img}}'
                            width="40px" height="40px" style="border-radius: 50%;">
                        <img title="s" *ngIf="participant.getSectionValue(section) === 'good'" src='{{faces[1].img}}' width="40px"
                            height="40px" style="border-radius: 50%;">
                        <img title="s" *ngIf="participant.getSectionValue(section) === 'insufficient'" src='{{faces[2].img}}'
                            width="40px" height="40px" style="border-radius: 50%;">
                        <img title="s" *ngIf="participant.getSectionValue(section) === 'poor'" src='{{faces[3].img}}' width="40px"
                            height="40px" style="border-radius: 50%;">
                    </div>
                </div>
                <div style="width: 10%;display: inline-block"></div>
            </div>
        </div>
        <div style="padding-top: 20px;"></div>
    </div>

    <div id="content-mobile-landscape">
        <div *ngFor="let section of sections" style="display: inline-block;width: 50%;text-align: center;">
            <div style="width: 5%;display: inline-block"></div>
            <div style="width: 80%;display: inline-block;">
                <h5 style="text-align: center;font-size:3vw;">{{getLanguageItem( section )}}</h5>
                <div style="text-align: center;display: inline;" *ngFor="let participant of participants">
                    <img title="s" *ngIf="participant.getSectionValue(section) === 'excellent'" src='{{faces[0].img}}'
                        width="40px" height="40px" style="border-radius: 50%;">
                    <img title="s" *ngIf="participant.getSectionValue(section) === 'good'" src='{{faces[1].img}}' width="40px"
                        height="40px" style="border-radius: 50%;">
                    <img title="s" *ngIf="participant.getSectionValue(section) === 'insufficient'" src='{{faces[2].img}}'
                        width="40px" height="40px" style="border-radius: 50%;">
                    <img title="s" *ngIf="participant.getSectionValue(section) === 'poor'" src='{{faces[3].img}}' width="40px"
                        height="40px" style="border-radius: 50%;">
                </div>
            </div>
            <div style="width: 5%;display: inline-block"></div>
        </div>
        <div style="padding-top: 20px;"></div>
    </div>
</div>

<div class="wbt-spacing-bottom-16 wbt-spacing-right-16"
    *ngIf="errMessage != ''"
    style="position: fixed; bottom: 0px; right: 0;">
    <tds-toast
        variant="{{toastVariant}}"
        header="{{toastHeader}}"
        subheader="{{errMessage}}">
    </tds-toast>
</div>
